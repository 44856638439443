const signature = {
  title: 'Assinaturas',
  loading: 'Carregando página de assinatura…',
  sign: 'Assinar documentos | Assinar | Assinar {documentsCount} documentos',
  info: '{signedCount}/{signersCount} Assinaturas',
  waiting:
    'Assinando documento ... | Assinando documento ... | Assinando documento {signedDocuments} de {totalDocuments} ...',
  massiveSignature: {
    signedFromTotal: 'Você já assinou {signedDocuments} de {totalDocuments} documentos.',
    loadingNextBatch: 'Carregando os próximos...',
  },
  security: 'Ambiente seguro Clicksign',
  step: {
    start: 'Começar',
    active: 'Etapa {stepActiveIndex} de {stepsCount}',
    next: 'Próximo',
    advance: 'Avançar',
    finalize: 'Finalizar',
    refusal: 'Recusar assinatura',
    report: 'Reportar um erro',
    termsOfAgreement: {
      text: 'Ao clicar em finalizar, você concorda com os {0} e {1} da Clicksign.',
      linkText: 'Termos de uso',
    },
    privacyPolicy: 'Política de Privacidade - LGPD',
    index: {
      title: 'Vamos assinar!',
      subtitle: 'Siga as etapas abaixo para finalizar a assinatura.',
      presentialSignatureSubtitle: 'Siga as etapas abaixo para finalizar a assinatura presencial.',
      signerInfo: 'Seus dados.',
      positionSign: 'Posicione sua assinatura.',
      selfieConfirmation: 'Selfie com documento.',
      addressProof: 'Comprovante de endereço.',
      handWrittenConfirmation: 'Assinatura manuscrita.',
      tokenConfirmation: 'Token de autenticação.',
      officialDocument: 'Foto do documento oficial.',
      pixConfirmation: 'Autenticação por Pix.',
      livenessConfirmation: 'Selfie dinâmica.',
      icpBrasilConfirmation: 'Certificado Digital',
      facialBiometricsConfirmation: '@:steps.facialBiometrics.title',
      signerLocation: 'Localização',
      voiceBiometrics: 'Biometria de voz',
    },
    info: {
      title: 'Confirme seus dados',
      form: {
        fullName: 'Nome completo',
        documentation: 'CPF',
        documentationPlaceholder: '___.___.___-__',
        birthday: 'Data de Nascimento (DD/MM/AAAA)',
        birthdayPlaceholder: '__/__/____',
        email: 'Receber via assinada no e-mail',
        emailInfo: 'A via será enviada após a conclusão de todas as assinaturas',
        emailPlaceholder: 'Digite seu email',
      },
    },
    expiredLink: {
      errorLabel: 'erro 410',
      title: 'Este link expirou',
      subtitle: 'Verifique se esse documento já foi assinado ou se o prazo de assinatura expirou',
      description:
        'Pode ser que este documento já tenha sido assinado por você. Verifique se há mais de um link para assinatura agrupado na mesma conversa de e-mail.',
      otherCase:
        'Não é esse o caso? Você também pode perguntar ao remetente do documento a razão da assinatura ter expirado.',
      help: 'Se precisar, visite nossa {0} ou envie um e-mail para {1}.',
      helpCenter: 'Central de ajuda',
      helpCenterTitle: 'Clique para acessar a central de ajuda',
      helpEmail: 'ajuda@clicksign.com',
      helpEmailTitle: 'Clique para enviar email',
      altClicksignLogo: 'Logo Clicksign',
    },
    token: {
      title: 'Token de autenticação',
      subtitle: 'Enviamos um código token para o',
      label: {
        default: 'Insira o token para finalizar',
        insert: 'Insira o Token e finalize a assinatura',
        presetToken: 'Agora é só finalizar a assinatura',
        error: 'Token inválido',
      },
      tokenSent: 'Enviado para {origin}',
      invalid: 'Token inválido',
      automatic: 'Token preenchido automaticamente',
      tooltip: 'O Token foi preenchido automaticamente para facilitar a assinatura do documento.',
      help: 'Não recebeu o token?',
      send: 'Enviar Token via ',
      resending: 'Enviando Token...',
      resend: 'Reenviar token via',
      resendTo: {
        whatsapp: 'WhatsApp',
        email: 'e-mail',
        sms: 'SMS',
      },
      timer: 'Aguarde {timer} para reenviar',
      remainingAttempts:
        'Resta apenas {remainingAttempts} envio de Token | Resta apenas {remainingAttempts} envios de Token',
      noMoreAttempts: 'Limite de tentativas de envio de Token alcançado',
      maxAttemptsHelp: 'Dificuldades para finalizar a autenticação?',
      sentSuccess: 'Token enviado com sucesso!',
      resentSuccess: 'Token reenviado com sucesso!',
      limitExceeded: 'Limite de tentativas excedido.',
      limitExceededDescription: '{0} Tente de novo em {1}.',
      email: 'e-mail',
      sms: 'SMS',
      whatsapp: 'WhatsApp',
      finalize: 'Finalizar',
      authenticated: 'Token autenticado',
      expired: '{0} Valide novamente na última etapa.',
      expiredBold: 'Token Expirado:',
      sentTo: {
        email: 'Enviamos um token para o e-mail',
        sms: 'Enviamos um token via SMS para',
        whatsapp: 'Enviamos um token via WhatsApp para',
      },
    },
    presential: {
      title: 'Vamos iniciar sua assinatura ',
      subtitle: 'Insira o código de acesso',
      successToken: 'Código validado com sucesso',
      action: 'Iniciar assinatura',
      expired: {
        title: 'Este código expirou',
        subtitle: 'Solicite um novo código de assinatura à pessoa que realizou o seu atendimento.',
        action: 'Retornar ao início',
        return: 'Retornando em {time} segundos...',
      },
      validation: {
        title: 'Validando código de acesso',
      },
      termsOfAgreement: {
        text: 'Ao avançar, você concorda com os {0} e {1} da Clicksign.',
      },
    },
    pix: {
      title: 'Abra o app do seu banco',
      copyAndPasteText: 'e use a opção Pix Copia e Cola',
      qrCodeAlternativeText: 'Ou pagar com QR Code',
      copyLabel: 'Ou utilizar a opção Pix Copia e Cola',
      actions: {
        copy: 'Copiar Código Pix',
        copied: 'Copiado',
        close: 'Fechar',
      },
      disclaimer: 'Use a sua conta bancária para pagar',
      help: 'Dificuldades com Pix?',
      whyPix: {
        action: 'Por que Pix?',
        modal: {
          title: 'Para assinar é preciso ter o Pix cadastrado na sua conta bancária.',
          whatIsPixTitle: 'O que é o Pix?',
          whatIsPixDescription:
            'Pix é um sistema de pagamentos instantâneos rápido e seguro criado pelo Banco Central.',
          whySignWithPixTitle: 'Porque assinar com Pix?',
          whySignWithPixDescription:
            'Quando você paga com Pix, conseguimos autenticar seus dados através da transação bancária.',
          whySignWithPixDescriptionTwo:
            'Essa autenticação dificulta a chance de fraudes, tornando a assinatura eletrônica ainda mais segura.',
          senderAuthMethod:
            '*Este método de autenticação da assinatura foi escolhido pelo remetente deste documento.',
        },
      },
      feedbackModal: {
        title: 'Qual sua dificuldade?',
        options: {
          dontHavePIX: 'Não tenho PIX',
          cantPay: 'Não consigo fazer a transação no momento',
          dontWantToSign: 'Não quero assinar com PIX',
          cantUnderstand: 'Não consegui entender como funciona',
          issuesWithMyBank: 'Tive um problema técnico com o banco',
          other: 'Outro',
        },
        actions: {
          next: 'Avançar',
          cancel: 'Cancelar',
        },
      },
      helpModal: {
        linkText: 'Veja como proceder aqui',
        description: 'Informe o remetente caso não consiga concluir a assinatura.',
        close: 'Fechar',
      },
      loading: 'Carregando QR Code...',
      subtitle: 'e use a opção {0}',
      qrCode: 'QR Code',
      pixCopyAndPaste: 'Pix Copia e Cola',
      cash: 'R$ {0}',
      alternativeSubtitle: 'Ou utilizar a opção {0}',
      payWith: 'Ou pagar com {0}',
      tutorial: {
        confirm: {
          title: 'Confirme que você é você',
          subtitle: 'Para isso será necessária uma autenticação bancária.',
        },
        pay: {
          title: 'Faça um Pix de R$0,01',
          subtitle: 'Use o Pix cadastrado na sua conta bancária.',
        },
        sign: {
          title: 'E assine com segurança',
          subtitle: 'A transação confirma seus dados e reduz o risco de fraudes.',
        },
      },
      next: 'Próximo',
      signWithPix: 'Assinar com Pix',
      pixFailed: {
        title: 'Assinatura não autenticada',
        cpfInformed:
          'Parece que o CPF informado não é o mesmo da conta bancária usada para fazer o Pix.',
        dontWorry: 'Não se preocupe, o valor será estornado.',
        tryAgain: 'Tente novamente com um Pix cadastrado na sua conta bancária.',
        actions: {
          tryAgain: 'Tentar novamente',
        },
      },
    },
    selfie: {
      analyzingSelfie: 'Analisando foto do seu rosto e documento',
      prepare: {
        title: 'Foto segurando o documento',
        takePhoto: 'Tirar foto',
        disclaimer: 'Sua foto será protegida por marca d’àgua.',
        documentAllowed: 'O documento pode ser seu RG ou CNH, ou outro {0} documento oficial.',
        dontCoverYourFace: 'Tenha cuidado para não cobrir seu rosto {0} com o documento.',
      },
      photo: {
        title: 'Se a {0} ficou nítida, continue para a próxima etapa.',
        boldTitle: 'foto do seu rosto com o documento',
        face: 'Rosto',
        takeAnother: 'Tirar outra foto',
      },
      notApproved: {
        title: 'Foto não reconhecida',
        newSelfie: 'Tire uma nova selfie com seu documento de identificação.',
        desc: 'Garanta que a imagem esteja nítida e lembre-se de não usar acessórios.',
        tryAgain: 'Tentar novamente',
      },
      error: {
        browserIncompatible: {
          name: 'Seu navegador não suporta a captura de foto.',
          description: 'Tente assinar através de outro navegador.',
        },
        cameraMissing: {
          name: 'Não foi possível encontrar uma câmera.',
          description: 'Assine com outro dispositivo, tente um celular.',
        },
        cameraBlocked: {
          name: 'Permissão negada para a câmera.',
          description: 'Tente habilitá-la e recarregue essa página.',
        },
        cameraBusy: {
          name: 'Sua câmera está em uso por outro aplicativo.',
          description: 'Feche os outros aplicativos e tente novamente.',
        },
        selfie: {
          name: 'A sua foto não pôde ser capturada.',
          description: 'Assine com outro dispositivo, tente um celular.',
        },
      },
    },
    handwritten: {
      title: 'Desenhe sua Assinatura Manuscrita',
      clear: 'Limpar',
      disclaimer: 'Sua assinatura manuscrita será protegida por marca d’água.',
      positionSignDisclaimer:
        "Sua assinatura será posicionada em locais específicos no documento e protegida por marca d'água.",
      reusableDisclaimer: 'Assinatura obtida do último uso',
    },
    positionSign: {
      nextDocument: 'Próximo documento',
    },
    officialDocument: {
      takePhoto: 'Tirar foto',
      next: 'Próximo',
      takeAnother: 'Tirar outra foto',
      prepare: {
        titleFront: 'Foto da frente do documento',
        titleBack: 'Foto do verso do documento',
        disclaimer: {
          useOfficialDocument: 'Foto do documento oficial, por exemplo RG ou CNH.',
          removeFromPlastic: 'Se for possível, retire o plástico.',
        },
      },
      photo: {
        title: 'Se a {0} ficou nítida, continue para a próxima etapa.',
        titleBoldFront: 'foto da frente do documento',
        titleBoldBack: 'foto do verso do documento',
        front: 'Frente',
        back: 'Verso',
      },
    },
  },
  facematchPending: {
    title: 'Documento com assinatura em processo',
    description:
      'Suas imagens estão sendo processadas. Você receberá um e-mail quando for concluído.',
  },
  facematchRefused: {
    title: 'Assinatura não realizada',
    description: 'Ainda não identificamos você. Tente novamente e tente usar outro documento.',
    button: 'Tente novamente',
  },
  alreadySigned: {
    title: 'Você já assinou este documento.',
    description: {
      anyDocumentsPending:
        'Quando todos assinarem, enviaremos o documento assinado para seu e-mail. Você também pode acessar a Clicksign para acompanhar este processo de assinatura e baixar o documento assinado assim que estiver disponível.|Quando todos assinarem, enviaremos os documentos assinados para seu e-mail. Você também pode acessar a Clicksign para acompanhar este processo de assinatura e baixar os documentos assinados assim que estiverem disponíveis.',
      allDocumentsClosed:
        'Enviamos o documento assinado para seu e-mail. Você também pode acessar a Clicksign para baixá-lo.|Enviamos os documentos assinados para seu e-mail. Você também pode acessar a Clicksign para baixá-los.',
    },
  },
  success: {
    title: 'Assinatura feita com sucesso! | Assinaturas feitas com sucesso.',
    dashboardArea: 'Voltar para Minha área',
    description:
      'Quando todos assinarem, você receberá um e-mail com o documento assinado.|Quando todos assinarem, você receberá um e-mail com os documentos assinados.',
    pix: 'Seu Pix foi recebido e seus dados, validados.',
    smartphoneImageAlt: 'Smartphone com página de assinatura de documentos da Clicksign aberta',
    cta: {
      user: {
        title: 'Seus documentos em um só lugar',
        description:
          'Acesse sua conta na Clicksign para assinar e baixar seus documentos em um único lugar.',
      },
      noUser: {
        title: 'Assine e solicite assinaturas online com validade jurídica',
        description: 'Crie uma conta na Clicksign para assinar seus documentos em um único lugar.',
      },
      advantagesTitle: 'Confira as vantagens de assinar com a Clicksign:',
      advantages: {
        digitalAndElectronicSignature: 'Assinaturas digital e eletrônica',
        multipleAuthKinds: 'Diversos tipos de Autenticação ',
        whatsappSmsAndEmail: 'Envios via WhatsApp, SMS ou e-mail',
        acceptancesAndDocumentsManagement: 'Gestão de documentos e aceites',
      },
      createAccount: 'Criar conta grátis',
      accessClicksign: 'Acessar a Clicksign',
      hasPendenciesTitle: 'Ainda há documentos para assinar',
      reusableManuscript: {
        title: 'Para sua assinatura ficar salva, cadastre-se ou faça login para assinar.',
        description:
          'Quando você assina um documento já logado na Clicksign, nós salvamos sua assinatura manuscrita para que você possa utilizar nos próximos documentos.',
        link: 'Entenda sobre salvar assinatura manuscrita',
      },
    },
  },
  failed: {
    title: 'Assinatura não realizada',
    subtitle: 'Houve um erro no documento. O remetente será avisado',
  },
  error: {
    conflict: 'Não há documentos para serem assinados.',
    connection: 'Erro de conexão. Tente novamente mais tarde!',
    forbidden: 'Token inválido',
    validation: 'Erro de validação',
    default: 'Erro inesperado. Tente novamente mais tarde!',
    invalidTokenRateLimit: 'Link enviado. Aguarde um minuto antes de tentar novamente.',
    unprocessed: 'Erro ao processar a assinatura',
    location: 'A localização é obrigatória.',
  },
  pending: {
    pendingDocumentsCounter: '{pendingCounter} documento | {pendingCounter} documentos',
    titleSingle: 'Você ainda tem {0} esperando para ser assinado',
    titleMultiple: 'Você ainda tem {0} esperando para serem assinados',
    action: 'Assinar documento | Assinar documentos',
    // FIXME: After fix component 'WidgetPendingDocumentsWarning' use in refusal feedback.
    // TODO: Remove titleOld and actionOld
    titleOld:
      'Ainda há {pendingDocumentsCount} documento aguardando sua assinatura. | Ainda há {pendingDocumentsCount} documentos aguardando sua assinatura.',
    actionOld: 'Ver para assinar',
  },
  kind: {
    sign: 'Assinar',
    witness: 'Assinar como testemunha',
    party: 'Assinar como parte',
    intervening: 'Assinar como interveniente',
    receipt: 'Assinar para acusar recebimento',
    approve: 'Assinar para aprovar',
    endorser: 'Assinar como endossante',
    endorsee: 'Assinar como endossatário',
    administrator: 'Assinar como administrador',
    guarantor: 'Assinar como avalista',
    transferor: 'Assinar como cedente',
    transferee: 'Assinar como cessionário',
    contractee: 'Assinar como contratada',
    contractor: 'Assinar como contratante',
    jointDebtor: 'Assinar como devedor solidário',
    issuer: 'Assinar como emitente',
    manager: 'Assinar como gestor',
    buyer: 'Assinar como parte compradora',
    seller: 'Assinar como parte vendedora',
    attorney: 'Assinar como procurador',
    legalRepresentative: 'Assinar como representante legal',
    coResponsible: 'Assinar como responsável solidário',
    validator: 'Assinar como validador',
    surety: 'Assinar como fiador',
    lessor: 'Assinar como locador',
    lessee: 'Assinar como locatário',
    ratify: 'Assinar para homologar',
    employer: 'Assinar como empregador',
    employee: 'Assinar como empregado',
    consenting: 'Assinar como anuente',
    accountant: 'Assinar como contador',
    debtor: 'Assinar como devedor',
    franchisee: 'Assinar como franqueado',
    creditor: 'Assinar como credor',
    franchisor: 'Assinar como franqueador',
    insured: 'Assinar como segurado',
    grantor: 'Assinar como outorgante',
    president: 'Assinar como presidente',
    partner: 'Assinar como sócio',
    comforter: 'Assinar como comodante',
    accountHolder: 'Assinar como correntista',
    distracted: 'Assinar como distratada',
    distracting: 'Assinar como distratante',
    lender: 'Assinar como mutuante',
    associate: 'Assinar como associado',
    broker: 'Assinar como corretor',
    bailee: 'Assinar como fiel depositário',
    collateralProvider: 'Assinar como caucionante',
    pledged: 'Assinar como consignado',
    consignee: 'Assinar como consignatário',
    lawyer: 'Assinar como advogado',
    grantee: 'Assinar como outorgado',
    borrower: 'Assinar como comodatário',
    guarantorSpouse: 'Assinar como cônjuge do fiador',
    legalGuardian: 'Assinar como responsável legal',
    director: 'Assinar como diretor(a)',
    realEstateBroker: 'Assinar como corretor de imóveis',
    insuranceBroker: 'Assinar como corretor de seguros',
    licensor: 'Assinar como licenciante',
    licensed: 'Assinar como licenciada',
    serviceProvider: 'Assinar como prestador(a) de serviços',
    secured: 'Assinar como afiançado',
    consented: 'Assinar como anuído',
    interveningGuarantor: 'Assinar como interveniente garantidor',
    consentingIntervenor: 'Assinar como interveniente anuente',
    surveyor: 'Assinar como vistoriador',
    buildingManager: 'Assinar como síndico(a)',
    intermediary: 'Assinar como intermediário(a)',
    condominiumMember: 'Assinar como condômino',
    owner: 'Assinar como proprietário(a)',
    resident: 'Assinar como morador(a)',
    treasurer: 'Assinar como tesoureiro(a)',
    secretary: 'Assinar como secretário(a)',
    signedAsSign: 'Assinou',
    signedAsApprove: 'Assinou para aprovar',
    signedAsParty: 'Assinou como parte',
    signedAsWitness: 'Assinou como testemunha',
    signedAsIntervening: 'Assinou como interveniente',
    signedAsReceipt: 'Assinou para acusar recebimento',
    signedAsEndorser: 'Assinou como endossante',
    signedAsEndorsee: 'Assinou como endossatário',
    signedAsAdministrator: 'Assinou como administrador',
    signedAsGuarantor: 'Assinou como avalista',
    signedAsTransferor: 'Assinou como cedente',
    signedAsTransferee: 'Assinou como cessionário',
    signedAsContractee: 'Assinou como contratada',
    signedAsContractor: 'Assinou como contratante',
    signedAsJointDebtor: 'Assinou como devedor solidário',
    signedAsIssuer: 'Assinou como emitente',
    signedAsManager: 'Assinou como gestor',
    signedAsBuyer: 'Assinou como parte compradora',
    signedAsSeller: 'Assinou como parte vendedora',
    signedAsAttorney: 'Assinou como procurador',
    signedAsLegalRepresentative: 'Assinou como representante legal',
    signedAsCoResponsible: 'Assinou como responsável solidário',
    signedAsValidator: 'Assinou como validador',
    signedAsSurety: 'Assinou como fiador',
    signedAsLessor: 'Assinou como locador',
    signedAsLessee: 'Assinou como locatário',
    signedAsRatify: 'Assinou para homologar',
    signedAsEmployer: 'Assinou como empregador',
    signedAsEmployee: 'Assinou como empregado',
    signedAsConsenting: 'Assinou como anuente',
    signedAsAccountant: 'Assinou como contador',
    signedAsDebtor: 'Assinou como devedor',
    signedAsFranchisee: 'Assinou como franqueado',
    signedAsCreditor: 'Assinou como credor',
    signedAsFranchisor: 'Assinou como franqueador',
    signedAsInsured: 'Assinou como segurado',
    signedAsGrantor: 'Assinou como outorgante',
    signedAsPresident: 'Assinou como presidente',
    signedAsPartner: 'Assinou como sócio',
    signedAsComforter: 'Assinou como comodante',
    signedAsAccountHolder: 'Assinou como correntista',
    signedAsDistracted: 'Assinou como distratada',
    signedAsDistracting: 'Assinou como distratante',
    signedAsLender: 'Assinou como mutuante',
    signedAsAssociate: 'Assinou como associado',
    signedAsBroker: 'Assinou como corretor',
    signedAsBailee: 'Assinou como fiel depositário',
    signedAsCollateralProvider: 'Assinou como caucionante',
    signedAsPledged: 'Assinou como consignado',
    signedAsConsignee: 'Assinou como consignatário',
    signedAsLawyer: 'Assinou como advogado',
    signedAsGrantee: 'Assinou como outorgado',
    signedAsLoanee: 'Assinou como comodatário',
    signedAsBorrower: 'Assinou como comodatário',
    signedAsGuarantorSpouse: 'Assinou como cônjuge do fiador',
    signedAsLegalGuardian: 'Assinou como responsável legal',
    signedAsDirector: 'Assinou como diretor(a)',
    signedAsRealEstateBroker: 'Assinou como corretor de imóveis',
    signedAsInsuranceBroker: 'Assinou como corretor de seguros',
    signedAsLicensor: 'Assinou como licenciante',
    signedAsLicensed: 'Assinou como licenciada',
    signedAsServiceProvider: 'Assinou como prestador(a) de serviços',
    signedAsSecured: 'Assinou como afiançado',
    signedAsConsented: 'Assinou como anuído',
    signedAsInterveningGuarantor: 'Assinou como interveniente garantidor',
    signedAsConsentingIntervenor: 'Assinou como interveniente anuente',
    signedAsSurveyor: 'Assinou como vistoriador',
    signedAsBuildingManager: 'Assinou como síndico(a)',
    signedAsIntermediary: 'Assinou como intermediário(a)',
    signedAsCondominiumMember: 'Assinou como condômino',
    signedAsOwner: 'Assinou como proprietário(a)',
    signedAsResident: 'Assinou como morador(a)',
    signedAsTreasurer: 'Assinou como tesoureiro(a)',
    signedAsSecretary: 'Assinou como secretário(a)',
  },

  photo: {
    confirmPhoto: 'Confirmo que a foto está correta',
  },
};

export default signature;
