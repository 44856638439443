const reports = {
  index: {
    reports: 'Relatórios',
    banner: {
      moreForYou: 'Muito mais poder para você',
      documentsAndFlow: 'gerenciar seus documentos e fluxos',
    },
    list: {
      audit: 'Auditoria da conta',
      documents: 'Documentos',
      signers: 'Signatários',
      flows: 'Fluxos',
      consumption: 'Consumo',
    },
  },
  actions: {
    download: {
      csv: 'Baixar CSV',
      xlsx: 'Baixar XLSX',
    },
    filter: 'Filtrar',
    generateReport: 'Gerar relatório',
    select: 'Selecione',
  },
  date: {
    start: 'Inicial',
    end: 'Final',
    created: 'Data de criação',
    finished: 'Data de finalização',
  },
  table: {
    rowsNumber: 'Número de linhas',
    documentStatuses: {
      canceled: 'Cancelado',
      closed: 'Finalizado',
      running: 'Em processo',
    },
    pendingSignature: 'Somente pendentes',
  },
  loading: 'Aguarde alguns instantes…',
  dataPlaceholder: 'Sem dados para visualização',
  document: {
    title: 'Relatório de Documentos',
    columns: {
      name: 'Nome do documento',
      status: 'Status do documento',
      createdAt: 'Data de criação',
      deadlineAt: 'Data limite',
      finishedAt: 'Data de finalização',
      userEmail: 'Email do operador',
      key: 'Chave do documento',
      signersCount: 'Nº de signatários',
      signaturesCount: 'Nº de assinaturas',
      refused: 'Com assinaturas recusadas',
    },
    responses: {
      yes: 'Sim',
      no: 'Não',
    },
    modal: {
      title: 'Detalhes do documento',
    },
  },
  signer: {
    title: 'Relatório de Signatários',
    generateReport: 'Gerar relatório',
    columns: {
      documentName: 'Nome do documento',
      documentStatus: 'Status do documento',
      documentUserEmail: 'Email do operador',
      documentKey: 'Chave do documento',
      signerEmail: 'Email do signatário',
      signerPhoneNumber: 'Celular',
      signatureStatus: 'Status da assinatura',
      signedAt: 'Data e Hora da assinatura',
      signerName: 'Nome',
      signerSignAs: 'Assinar como',
      documentCreatedAt: 'Data de criação',
      documentDeadlineAt: 'Data limite',
      documentFinishedAt: 'Data de finalização',
      signerDocumentation: 'CPF',
      signerBirthday: 'Data de nascimento',
      signerAuth: 'Autenticação via',
      signatureValidation: 'Verificação da Receita Federal',
      documentUrl: 'URL do documento',
      filterSign: 'Filtrar Assinaturas',
    },
    dataPlaceholder: 'Sem dados para visualização',
    modal: {
      title: 'Detalhes do signatário',
    },
  },
  flow: {
    title: 'Relatório de Fluxos',
    limit: 'Número de linhas',
    selectFlow: 'Selecione um fluxo',
    noFlows: 'Sem fluxos adicionados.',
    createFlow: 'Crie um fluxo.',
    columns: {
      documentName: 'Nome do documento',
      documentStatus: 'Status do documento',
      formOneFilledAt: 'Preenchimento do formulário 1',
      formTwoFilledAt: 'Preenchimento do formulário 2',
      document_url: 'URL do documento',
    },
    tooltips: {
      noFlows: 'Exibindo apenas os fluxos que você tem permissão.',
    },
    modal: {
      title: 'Detalhes do fluxo',
      unanswered: 'Não informado',
    },
  },
  consumption: {
    title: 'Histórico de Consumo',
    periodTypes: {
      fixed: {
        label: 'Por período de fatura',
        tooltip: 'Data inicial e final do consumo da fatura',
      },
      custom: 'Por data personalizada',
    },
    period: 'Período',
    limit: 'Número de linhas',
    showingItems: 'Exibindo {0} de {1}',
    showAllItems: 'Exibir todos',
    generateReport: 'Gerar',
    downloadCsv: 'Baixar CSV',
    exportCsv: 'Exportar CSV',
    downloadXlsx: 'Baixar XLSX',
    content: {
      title: 'Exibindo resultados do período de fatura escolhido',
    },
    data: {
      title: {
        custom: 'Exibindo período por data personalizada',
        fixed: 'Exibindo período em que foi gerado uma fatura fechada',
      },
      empty: {
        title: 'Sem dados de consumo para exibir',
        text: 'Selecione um período ou data e clique em gerar',
        fixed: 'Selecione um período e clique em gerar',
      },
    },
    columns: {
      productName: 'Produto',
      quantity: 'Quantidade',
      documentKey: 'Chave do documento',
      documentName: 'Nome do documento',
      documentUserEmail: 'E-mail do operador',
      documentFinishedAt: 'Data de finalização',
    },
    modal: {
      title: 'O arquivo CSV será enviado para {userEmail}',
      content: 'Você receberá nosso e-mail em breve.',
    },
    alert: {
      exportCSV: 'clique em Exportar CSV.',
      support: 'entre em contato com o suporte.',
      message:
        'Exibindo 500 dos {0} resultados encontrados. Para visualizar todos os resultados, {1}',
    },
    usage: {
      helpLink: 'Como funciona a cobrança?',
      numberOfRows: '{rows} linhas',
      headers: {
        details: {
          signerKey: 'Chave do signatário',
          signerEmail: 'Signatário',
          whatsappSignerPhoneNumber: 'Signatário',
          signerPhoneNumber: 'Telefone do signatário',
          createdAt: 'Data de criação',
          contentName: 'Nome do documento',
          key: 'Chave do documento',
          finishedAt: 'Data de finalização',
          userEmail: 'Operador',
          sendAt: 'Data do disparo',
          resendAttempts: 'Tentativas',
        },
      },
      plans: {
        budget: 'Plano Budget',
        basic: 'Plano Basic',
        growth: 'Plano Growth',
        custom: 'Plano Custom',
        standard: 'Plano Standard',
        ilimitado: 'Plano Ilimitado',
        fluxia: 'Plano Fluxia',
        eventual: 'Plano Eventual',
        essencial: 'Plano Essencial',
        profissional: 'Plano Profissional',
        empresarial: 'Plano Empresarial',
        integration: 'Plano Integração',
      },
      products: {
        sms: 'SMS',
        document: 'Documentos',
        whatsapp: 'WhatsApp',
        user: 'Usuários adicionais',
        biometrics_authentication_caf_serpro: 'Biometria facial SERPRO',
        facial_biometrics_caf: 'Biometria facial',
        documentscopy_authentication: 'Documentoscopia',
        acceptance_term_whatsapp: 'Aceites via WhatsApp',
        timestamp: 'Carimbo de tempo',
      },
      period: {
        general: 'Exibindo relatório de consumo de {startDate} a {endDate}',
        document: 'Documentos assinados (de {startDate} a {endDate})',
        facial_biometrics_caf: 'Biometria facial (de {startDate} a {endDate})',
        biometrics_authentication_caf_serpro: 'Biometria SERPRO (de {startDate} a {endDate})',
        documentscopy_authentication: 'Documentoscopia (de {startDate} a {endDate})',
        whatsapp: 'WhatsApp disparado (de {startDate} a {endDate})',
      },
      button: {
        title: 'Baixar relatório completo',
        download: 'Baixar CSV',
      },
      empty: {
        message: 'Selecione um período',
      },
    },
  },
};

export default reports;
