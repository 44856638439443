import { useStore } from '@/store';
import { useRouter } from '@base/hooks';
import { computed, ref } from 'vue';

export const useFormFlowPendingProcesses = () => {
  const store = useStore();
  const router = useRouter();

  const loading = ref(false);
  const modalInvalidType = ref('hasPendingProcesses');

  const pendingProcesses = computed(() => store.getters['formFlow/getPendingProcesses']);
  const waitingSecondFormProcesses = computed(
    () => store.getters['formFlow/getWaitingSecondFormProcesses']
  );
  const verifyPendingProcessesLinks = computed(
    () => store.getters['formFlow/getVerifyPendingProcessesLinks']
  );
  const hasPendingProcesses = computed(() => store.getters['formFlow/getHasPendingProcesses']);
  const hasWaitingSecondFormProcesses = computed(
    () => store.getters['formFlow/getHasWaitingSecondFormProcesses']
  );
  const hasProcessesOrAnswers = computed(
    () => hasPendingProcesses.value || hasWaitingSecondFormProcesses.value
  );
  const links = computed(() => store.getters['info/getLinks']);
  const selectedFlow = computed(() => store.getters['formFlow/getSelectedFlow']);

  const handleGoToProcesses = () => {
    router.push({
      path: links.value.navigation.automation.flowProcesses,
      query: { flowFormId: [selectedFlow.value.id] },
    });
  };

  const handleGoToPendingAnswers = () => {
    router.push({
      path: links.value.navigation.automation.pendingAnswers,
      query: { flowFormId: [selectedFlow.value.id] },
    });
  };

  const verifyPendingProcesses = async () => {
    loading.value = true;
    await store.dispatch('formFlow/verifyPendingProcesses');
    if (hasWaitingSecondFormProcesses.value) {
      modalInvalidType.value = 'hasWaitingSecondFormProcesses';
    } else {
      modalInvalidType.value = 'hasPendingProcesses';
    }
    loading.value = false;
  };

  const bulkVerifyPendingProcesses = async (ids = []) => {
    loading.value = true;
    await store.dispatch('formFlow/bulkVerifyPendingProcesses', { ids });
    loading.value = false;
  };

  return {
    verifyPendingProcesses,
    handleGoToPendingAnswers,
    handleGoToProcesses,
    hasPendingProcesses,
    hasWaitingSecondFormProcesses,
    modalInvalidType,
    hasProcessesOrAnswers,
    loading,
    pendingProcesses,
    waitingSecondFormProcesses,
    verifyPendingProcessesLinks,
    bulkVerifyPendingProcesses,
  };
};
