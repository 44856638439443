<script setup>
import { computed, onMounted, ref } from 'vue';
import { useModal, useRoute, useRouter, useToast } from '@base/hooks';
import { useI18n } from '@/locales';
import {
  FormFlowEmptyList,
  FormFlowCreateFolderModal,
  FormFlowFoldersMenuActions,
  FormFlowDeleteFolderModal,
  FormFlowFoldersListFilters,
  FormFlowEmptySearch,
} from '@flow/components/form-flow';
import { useStore } from '@/store';
import { camelizeKeys } from '@base/utils';
import FormFlowRenameFolderModal from '../FormFlowRenameFolderModal/FormFlowRenameFolderModal.vue';

const { isModalActive, openModal, closeModal } = useModal();
const toast = useToast();
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();

const isLoading = ref(false);

const folders = computed(() => store.getters['flowFolder/getFolders']);
const pagination = computed(() => store.getters['flowFolder/getPagination']);
const totalItems = computed(() => store.getters['flowFolder/getTotalItems']);
const hasFilters = computed(() => !!route.query?.name);

const HEADERS = [
  {
    label: t('flowModule.folders.table.name'),
    value: 'name',
    colspan: 6,
    sortable: true,
  },
  {
    label: t('flowModule.folders.table.flowNumber'),
    value: 'flowsCount',
    sortable: true,
  },
  {
    label: t('flowModule.folders.table.createdAt'),
    value: 'createdAt',
    sortable: true,
  },
  {
    label: t('flowModule.folders.table.actions'),
    value: 'action',
    colspan: 2,
  },
];

const fetchFlowFolders = async () => {
  isLoading.value = true;
  await store.dispatch('flowFolder/fetchFlowFolders', { self: window.location.href });
  isLoading.value = false;
};

const handleErrorResponse = (err, defaultMessage) => {
  const errors = err.response?.data?.flowFolder?.errors;

  if (errors) {
    Object.values(errors).forEach((value) => toast.error(value[0]));
  } else {
    toast.error(defaultMessage);
  }
};

const handleCreate = async ({ name }) => {
  try {
    await store.dispatch('flowFolder/createFlowFolder', { name });
    fetchFlowFolders();
    toast.success(t('flowModule.folders.create.successMessage'));
    closeModal();
  } catch (err) {
    handleErrorResponse(err, t('flowModule.folders.create.errorMessage'));
  }
};

const handleRename = async ({ name }) => {
  try {
    await store.dispatch('flowFolder/renameFlowFolder', { name });
    fetchFlowFolders();
    toast.success(t('flowModule.folders.rename.successMessage'));
    closeModal();
  } catch (err) {
    handleErrorResponse(err, t('flowModule.folders.rename.errorMessage'));
  }
};

const handleDelete = async () => {
  try {
    await store.dispatch('flowFolder/deleteFlowFolder');
    fetchFlowFolders();
    toast.success(t('flowModule.folders.delete.successMessage'));
    closeModal();
  } catch (err) {
    handleErrorResponse(err, t('flowModule.folders.delete.errorMessage'));
  }
};

const handleAction = (actionName, folder) => {
  const ACTION_MODAL_MAPPING = {
    rename: 'FormFlowRenameFolderModal',
    delete: 'FormFlowDeleteFolderModal',
  };

  store.commit('flowFolder/SET_SELECTED_FOLDER', folder);

  openModal(ACTION_MODAL_MAPPING[actionName]);
};

const ORDER_FIELDS_MAPPING = {
  name: 'name',
  createdAt: 'created_at',
  flowsCount: 'flows_count',
};

const handleSorted = async (order, column) => {
  let orderDirection;

  if (ORDER_FIELDS_MAPPING[column.value] === route.query.orderBy) {
    orderDirection = route.query.orderDirection === 'asc' ? 'desc' : 'asc';
  }

  await router.replace({
    query: {
      ...camelizeKeys(route.query),
      orderBy: ORDER_FIELDS_MAPPING[column.value],
      orderDirection: orderDirection || order,
    },
  });
};

onMounted(async () => {
  await fetchFlowFolders();
});
</script>

<template>
  <div>
    <XCard>
      <FormFlowFoldersListFilters
        v-if="totalItems != 0 || hasFilters"
        data-testid="foldersListFilters"
        @new-folder="openModal('FormFlowCreateFolderModal')"
      />
      <XTable
        v-if="folders.length || isLoading"
        :headers="HEADERS"
        :items="folders"
        :loading="isLoading"
        data-testid="foldersTable"
        @sort="handleSorted"
      >
        <template #items-col-name="{ item }">
          <XLink
            :class="$style.text"
            :to="item.links.self"
            data-testid="formFlowFoldersName"
          >
            {{ item.name }}
          </XLink>
        </template>
        <template #items-col-action="{ item }">
          <div :class="$style.tableActions">
            <FormFlowFoldersMenuActions
              :folder="item"
              data-testid="formFlowFoldersMenuActions"
              @rename="handleAction('rename', item._raw)"
              @delete="handleAction('delete', item._raw)"
            />
          </div>
        </template>
      </XTable>
      <template v-else>
        <FormFlowEmptySearch
          v-if="hasFilters"
          :button-text="$t('flowModule.emptySearch.folderButton')"
          data-testid="formFlowEmptySearch"
        />
        <FormFlowEmptyList
          v-else
          icon="folder"
          :title="$t('flowModule.folders.emptyList.title')"
          :description="$t('flowModule.folders.emptyList.description')"
          :action-text="$t('flowModule.folders.emptyList.actionText')"
          action-design="outlined"
          data-testid="formFlowEmptyList"
          @handle-new="openModal('FormFlowCreateFolderModal')"
        />
      </template>

      <XPagination
        v-if="pagination?.pageCount > 1"
        :class="$style.containerPagination"
        align="center"
        :active-page="pagination?.page"
        :total-pages="pagination?.pageCount"
        :next-text="$t('general.action.next')"
        :prev-text="$t('general.action.previous')"
        data-testid="containerPagination"
      />
    </XCard>

    <FormFlowCreateFolderModal
      :show-modal="isModalActive('FormFlowCreateFolderModal')"
      data-testid="createFolderModal"
      @close="closeModal"
      @create="handleCreate"
    />
    <FormFlowRenameFolderModal
      :show-modal="isModalActive('FormFlowRenameFolderModal')"
      data-testid="tableListRenameFolderModal"
      @close="closeModal"
      @rename="handleRename"
    />
    <FormFlowDeleteFolderModal
      :show-modal="isModalActive('FormFlowDeleteFolderModal')"
      data-testid="tableListDeleteFolderModal"
      @close="closeModal"
      @delete="handleDelete"
    />
  </div>
</template>

<style lang="scss" module>
.containerPagination {
  margin-top: var(--space-regular);
}

.tableActions {
  display: flex;
  justify-content: center;
}

.text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
