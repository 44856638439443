<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from '@/store';
import { useRoute, useRouter } from '@base/hooks';
import { useDebounceFn } from '@vueuse/core';

defineEmits(['new-folder']);

const MIN_TEXT_LENGTH = 3;
const SEARCH_DEBOUNCE_DELAY = 400;

const store = useStore();
const router = useRouter();
const route = useRoute();

const name = ref('');

const totalItems = computed(() => store.getters['flowFolder/getTotalItems']);
const nameParam = computed(() => route.query.name || '');

const skipSearch = (textSearch) => {
  const trimmed = textSearch.trim();
  const textSearchIsEmpty = trimmed.length === 0;

  return trimmed.length < MIN_TEXT_LENGTH && !textSearchIsEmpty;
};

const handleSearch = () => {
  if (skipSearch(name.value)) return;

  router.replace({
    query: {
      name: name.value.trim(),
    },
  });
};

const debouncedSearch = useDebounceFn(handleSearch, SEARCH_DEBOUNCE_DELAY);

onMounted(() => {
  name.value = nameParam.value;
});

watch(() => name.value, debouncedSearch);
</script>

<template>
  <div :class="$style.actions">
    <div :class="$style.filterContainer">
      <XTextInput
        v-model="name"
        :class="$style.search"
        :placeholder="$t('flowModule.filters.search')"
        full-width
        autofocus
        grouped
        data-testid="formFlowListFiltersTextSearch"
        @keyup.native.enter="handleSearch"
      >
        <XButton
          model="plain"
          @click="handleSearch"
        >
          <XIcon icon="search" />
        </XButton>
      </XTextInput>
      <span :class="$style.spanItems">
        {{ $tc('flowModule.folders.table.folderLength', totalItems, { count: totalItems }) }}
      </span>
    </div>
    <XButton
      size="sm"
      design="outlined"
      data-testid="newFolderButton"
      @click="$emit('new-folder')"
    >
      {{ $t('flowModule.folders.table.newFolder') }}
      <XIcon
        icon="folder-plus"
        right
      />
    </XButton>
  </div>
</template>

<style lang="scss" module>
.actions {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-medium);

  .filterContainer {
    display: flex;
    align-items: center;
    flex: 1;
    gap: var(--space-small-x);

    .search {
      max-width: 390px;
    }

    .spanItems {
      color: var(--color-neutral-700);
    }
  }
}
</style>
