export default {
  getLinks: (state) => state.links,
  getFormFlows: (state) => state.formFlows,
  getFormFlow: (state) => state.formFlow,
  getDataMappings: (state) => state.dataMappings,
  getSelectedFlow: (state) => state.selectedFormFlow,
  getPagination: (state) => state.pagination,
  getLoading: (state) => state.loading,
  getFlowPermissions: (state) => state.flowPermissions,
  getHasPendingProcesses: (state) => state.hasPendingProcesses,
  getHasWaitingSecondFormProcesses: (state) => state.hasWaitingSecondFormProcesses,
  getTotalItems: (state) => state.totalItems,
  getPendingProcesses: (state) => state.pendingProcesses,
  getWaitingSecondFormProcesses: (state) => state.waitingSecondFormProcesses,
  getVerifyPendingProcessesLinks: (state) => state.verifyPendingProcessesLinks,
  getFlowsLinks: (state) => state.flowsLinks,
  getMoveFolders: (state) => state.moveFolders,
  getMoveFoldersPagination: (state) => state.moveFoldersPagination,
};
