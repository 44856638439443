export const FORM_FLOW_ACTIONS_CONSTANTS = {
  edit: 'edit',
  copyFormLink: 'copy-form-link',
  disable: 'disable',
  duplicate: 'duplicate',
  privacy: 'privacy',
  info: 'info',
  removeFromFolder: 'remove-from-folder',
  delete: 'delete',
};
