const signer = {
  status: {
    notSigned: 'No firmado',
    pending: 'Esperando firma',
    signed: 'Firmado',
    canceled: 'Firma cancelada',
  },
  error: {
    generic: {
      action: 'Ha ocurrido un error inesperado. Vuelve a intentarlo o contacta al equipo de',
      supportLinkText: 'soporte.',
    },
  },
  details: {
    errorOnLoad: 'No fue posible obtener los detalles del firmante, inténtalo de nuevo',
    label: {
      communicateBy: 'Enviado por',
      authAs: 'Autenticar usando',
      refusalSignature: 'Rechazo de la firma',
      email: 'Correo electrónico',
      phoneNumber: 'Número de celular',
      cpf: 'CPF',
      birthday: 'Fecha de nacimiento',
      signedAt: 'Fecha de la firma',
      lastSignedAt: 'Fecha de los documentos firmados',
      nameVerification: 'Verificación de nombre',
      requiredAuth: 'Autenticación obligatoria',
    },
    refusalEnabled: 'habilitado',
    information: 'Información',
    additionalAuths: 'Autenticaciones adicionales',
    pixVerification: '{0} (E2EId / Banco Central)',
    nameVerification: {
      conferred: 'El nombre coincide con la base de datos de la Receita Federal.',
      cpfNotFound: 'El CPF registrado no existe en la base de datos de la Receita Federal.',
      cpfInvalid: 'El CPF ingresado no es válido.',
      divergentName:
        'El nombre ingresado no coincide con el registrado en la base de datos de la Receita Federal. ({name})',
      divergentBirthday:
        'La fecha de nacimiento ingresada no coincide con el registrado en la base de datos de la Receita Federal. ({birthday})',
      receitaProxyFail: 'Ocurrió un error durante la consulta de datos en la Receita Federal.',
      pending: 'Verificación en progreso.',

      title: 'Verificación del nombre',
      cpfEmpty: 'Verificación no realizada.',
      cpfRegularizationPending:
        'El CPF registrado está en proceso de regularización pendiente en la base de datos de la Receita Federal.',
      cpfOwnerDeceased:
        'El propietario del CPF ha fallecido según la base de datos de la Receita Federal.',
      cpfSuspended:
        'El CPF registrado está suspendido según la base de datos de la Receita Federal.',
      cpfCanceled: 'El CPF registrado está cancelado según la base de datos de la Receita Federal.',
      cpfNull:
        'El CPF fue anulado tras la identificación de fraude en el registro según la base de datos de la Receita Federal.',
      signerValidatorFail: 'Ocurrió un error durante la consulta de datos en la Receita Federal.',
    },
    icpBrasil:
      '{subjectName} {certificateType} firmó con un certificado digital ICP-Brasil válido hasta {validityEnd}. Número de identificación fiscal: {cpf}, Número de serie del certificado ICPBR: {serialNumber}',
    auths: {
      api: 'API',
      handwritten: 'Firma manuscrita',
      liveness: 'Selfie dinámica',
      officialDocument: 'Documento oficial',
      selfie: 'Selfie con documento',
      facialBiometrics: 'Biometría facial',
      icpBrasil: 'Certificado digital',
      pix: 'Pix',
      email: 'Token por correo electrónico',
      whatsapp: 'Token por WhatsApp',
      sms: 'Token por SMS',
      addressProof: 'Comprobante de domicilio',
      biometric: 'Biometría facial - CAF y SERPRO',
      documentscopy: 'Documentoscopia',
    },
  },
  actions: {
    seeMore: 'Ver más {count} documento | Ver más {count} documentos',
    seeLess: 'Ver menos',
    sendReminder: 'Enviar recordatorio',
    seeDispatchState: 'Seguimiento del envío',
  },
  singleRemindModal: {
    title: 'Enviar solicitud de firma',
    description:
      'El firmante recibirá una solicitud con un enlace para proceder con la firma. Puedes agregar un mensaje personalizado, si lo deseas.',
    whatsappAlert:
      'Para los envíos por WhatsApp, la solicitud tiene un costo extra y no es posible enviar un mensaje',
  },
  contactsStep: {
    edit: {
      title: 'Editar contacto',
    },
    add: {
      title: 'Agregar contacto',
    },
    delete: {
      title: 'Eliminar contacto de la lista | Eliminar contactos de la lista',
      description:
        '¿Estás seguro de que deseas eliminar {0} contacto? | ¿Estás seguro de que deseas eliminar {0} contactos?',
      loading: 'Eliminando {count} de {total}...',
    },
    title: 'Lista de contactos',
    cancel: 'Cancelar',
    confirm: 'Avanzar',
    search: 'Buscar contacto',
    table: {
      name: 'Nombre',
      namePlaceholder: 'Ingresar el nombre del contacto',
      email: 'Correo electrónico',
      contact: 'Contacto',
      auth: 'Autenticación',
      actions: 'Acciones',
      auths: {
        api: 'API',
        handwritten: 'Firma manuscrita',
        liveness: 'Selfie dinámica',
        officialDocument: 'Documento oficial',
        selfie: 'Selfie con documento',
        pix: 'Pix',
        icpBrasil: 'Certificado digital',
        facialBiometrics: 'Biometría facial',
        email: 'Token por correo electrónico',
        whatsapp: 'Token por WhatsApp',
        sms: 'Token por SMS',
        biometric: 'Biometría facial - CAF y SERPRO',
        addressProof: 'Comprobante de domicilio',
      },
      info: '+Info',
      emptyInfo: 'No hay información registrada',
      empty: 'Aún no hay nada aquí. ¿Te gustaría agregar tu primer contacto?',
      emptySearch: 'No se encontraron contactos. ¿Te gustaría realizar una nueva búsqueda?',
      edit: 'Editar',
      favorite: 'Favorito',
      tabs: {
        all: 'Todos',
        starred: 'Favoritos',
      },
      blockedFunctionalityTooltip: {
        title: '{blockedFunctionality} no disponible para tu cuenta',
        description1:
          'Agrega este firmante nuevamente, seleccionando otra forma de envío y autenticación.',
        description2: 'Para habilitar {blockedFunctionality}, contacta al equipo de soporte.',
        whatsapp: 'WhatsApp',
        sms: 'SMS',
        liveness: 'Autenticación por Selfie Dinámica',
        facialBiometrics: 'Autenticación por Biometría',
        whatsappWithArticle: 'WhatsApp',
        smsWithArticle: 'SMS',
        livenessWithArticle: 'Autenticación por Selfie Dinámica',
        facialBiometricsWithArticle: 'Autenticación por Biometría',
      },
    },
    info: {
      birthday: 'Fecha de nacimiento',
      documentation: 'Número de identificación fiscal',
      phoneNumber: 'Número de celular',
    },
  },
  infoStep: {
    envelope: {
      title: 'Agregar nuevo firmante',
      hasDocumentation: 'Solicitar el CPF al firmante',
      documentationRequired:
        'La autenticación seleccionada requiere el número de identificación fiscal',
      emptyState: 'Debe agregarse al menos una autenticación.',
      email: {
        label: 'Correo electrónico',
        placeholder: 'Ingresa el correo electrónico',
      },
      phoneNumber: {
        label: 'Número de celular',
        placeholder: 'Ingresa el número de celular',
      },
      sections: {
        personalInfo: 'Información adicional',
      },
      actions: {
        change: 'Cambiar',
        add: 'Agregar',
      },
      authsModal: {
        title: 'Cambiar autenticaciones',
        description: 'Desplaza el cursor sobre las autenticaciones para obtener más información.',
        isAnyAuthSelected: 'Selecciona al menos una autenticación',
        whatsappBlocked: '@:signer.premium.upgradePlan para usar el Token vía WhatsApp',
        selfieBlocked: '@:signer.premium.upgradePlan para usar la Selfie con documento',
        facialBiometricsBlocked: '@:signer.premium.upgradePlan para usar la Biometría facial',
        livenessBlocked: '@:signer.premium.upgradePlan para usar la Selfie dinámica',
        smsWarning: 'El envío por SMS generará un costo adicional.',
        smsWarningHelp: 'Conoce más sobre el costo del SMS',
        auths: {
          // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
          // TODO: Remove 'tokenSimplified'
          tokenSimplified: 'Token por correo electrónico',
          token: 'Token por',
          email: 'Correo electrónico',
          sms: 'SMS',
          pix: 'Pix',
          icpBrasil: 'Certificado Digital',
          whatsapp: 'WhatsApp',
          handwritten: 'Firma manuscrita',
          liveness: 'Selfie dinámica',
          officialDocument: 'Documento oficial',
          facialBiometrics: 'Biometría facial',
          selfie: 'Selfie con documento',
          addressProof: 'Comprobante de domicilio',
        },
        authsInfo: {
          link: 'Conoce más sobre esta autenticación',
          token: {
            title: 'Token de confirmación',
            description:
              'Se enviará un Token (código) a través de correo electrónico o WhatsApp para confirmar la identidad del firmante. El Token funciona como un segundo factor de autenticación.',
            // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
            // TODO: Remove 'tokenSimplified'
            descriptionSimplified:
              'Se enviará un Token (código) a través de correo electrónico para confirmar la identidad del firmante. El Token funciona como un segundo factor de autenticación.',
          },
          handwritten: {
            title: 'Firma manuscrita',
            description:
              'Se solicitará la firma en la pantalla, la cual se adjuntará al final del documento firmado.',
            positionSignDescription:
              'Se solicitará la firma en la pantalla y el resultado se adjuntará al documento firmado.',
            positionSignComplement: '¡Ahora puedes decidir dónde colocar la firma en el documento!',
          },
          selfie: {
            title: 'Selfie con documento',
            description:
              'Se solicitará al firmante que tome una foto sosteniendo su documento de identificación (por ejemplo: RG, CNH). La imagen capturada se adjuntará al final del documento firmado.',
          },
          addressProof: {
            title: 'Comprobante de domicilio',
            description:
              'Se solicitará al firmante que tome una foto del comprobante de domicilio. La imagen capturada se adjuntará al final del documento firmado.',
          },
          officialDocument: {
            title: 'Documento oficial',
            description:
              'Se solicitará al firmante que tome una foto de su documento de identificación (por ejemplo: RG, CNH). La imagen capturada se adjuntará al final del documento firmado.',
          },
          liveness: {
            title: 'Selfie dinámica',
            description: 'Se solicitará al firmante que tome una foto de su rostro en ese momento.',
            complement:
              'La imagen será validada (prueba de vida) para confirmar la existencia de una persona real en la firma del documento. La imagen capturada se adjuntará al final del documento firmado.',
          },
          pix: {
            title: 'Pix',
            description:
              'Se solicitará al firmante que realice una transferencia simbólica (Pix) por el valor de R$0,01 (un centavo) desde una cuenta bancaria vinculada a su propio número de identificación fiscal.',
            complement:
              'Esto permite comparar el CPF registrado y el registrado en el Banco Central.',
          },
          facialBiometrics: {
            title: 'Biometría facial',
            description:
              'Se solicitará al firmante que tome una foto de su documento de identificación (por ejemplo: RG, CNH) y de su rostro.',
            complement:
              'La imagen del rostro será comparada con la foto del documento (verificación facial). Las imágenes capturadas se adjuntarán al final del documento firmado.',
          },
          icpBrasil: {
            title: 'Certificado Digital',
            description:
              'El firmante debe tener un certificado A1 o A3 emitidos en su CPF para firmar. No es posible usar otras autenticaciones junto con el certificado.',
            complement:
              'Si deseas que el documento firmado se genere en formato PDF/A, contacta al equipo de soporte antes de enviar el documento para firma.',
          },
        },
      },
    },
    title: 'Nuevo firmante',
    cancel: 'Cancelar',
    confirm: 'Avanzar',
    fullName: 'Nombre completo',
    cpf: 'CPF',
    birthday: 'Fecha de nacimiento',
    signerHasCpf: 'El firmante cuenta con CPF',
    isForeigner: 'Este firmante es extranjero',
    getCpf: 'Solicitar el CPF al firmante',
    signerNotHasCpf: 'No cuenta con CPF',
    formLabel: {
      label: 'Ingresa un título para identificar a este firmante',
      placeholder: 'Ej: Director',
    },
    email: {
      label: 'Correo electrónico',
      placeholder: 'Ingresa el correo electrónico del firmante',
    },
    auth: {
      label: 'Obligatorias',
      labelOld: 'Autenticación obligatoria',
      placeholder: 'Elige el método de autenticación del firmante.',
    },
    communicateBy: {
      label: 'Enviar documento para firma por',
      placeholder: 'Seleccionar...',
      warning: 'El envío vía WhatsApp generará un costo adicional.',
      warningHelp: 'Conoce más sobre el costo de WhatsApp',
    },
    phoneNumber: {
      label: 'Número de celular',
      placeholder: '( ) __ ____-____',
    },
    signAs: {
      label: 'Firmar como',
      placeholder: 'Selecciona cómo firmará el firmante',
    },
    sections: {
      communication: 'Envío',
      auths: 'Autenticaciones',
      personalInfo: 'Datos personales',
      auth: 'Autenticación',
      sign: 'Firma',
    },
    additionalAuths: {
      title: 'Autenticaciones adicionales',
      label: 'Adicionales',
      tooltip: 'Al elegir, esta autenticación también será obligatoria para el firmante.',
      helpLink: '¿Para qué sirven?',
    },
    message: {
      description: 'Si el mensaje es respondido, será enviado a tu correo electrónico',
    },
    description: {
      label: 'Escribe un título para identificar a este firmante',
      placeholder: 'Ejemplo: Director',
    },
    whatsappSignature: {
      label: 'Firma vía WhatsApp',
      enabled: 'Firma vía WhatsApp activada.',
      disabled: 'Firma vía WhatsApp desactivada.',
      helpLink: 'Ver cómo funciona',
    },
    whatsappFormField: {
      emptyList: 'No hay números de teléfono disponibles para seleccionar',
    },
    formField: {
      label: 'Correo electrónico del firmante',
      emptyList: 'No hay correos electrónicos disponibles para seleccionar',
      tooltip: 'Selecciona el título del firmante que debe recibir el documento para firmar.',
    },
    saveAsContact: {
      label: 'Guardar en la lista de contactos',
    },
    formSigner: {
      title: 'Agregar firmante desde el formulario',
      description:
        'Elige esta opción cuando el correo electrónico y WhatsApp del firmante se obtengan del formulario.',
    },
    spreadsheetSigner: {
      title: 'Agregar firmante desde la hoja de cálculo',
      description: 'Los datos de este firmante serán ingresados a través de la hoja de cálculo.',
    },
    icpBrasilEnabled: {
      info: 'Las autenticaciones adicionales no están disponibles para el Certificado Digital',
      text: 'La firma con Certificado Digital solo será válida si el CPF del certificado y del firmante son iguales.',
    },
  },
  premium: {
    text: 'Desbloquea más funcionalidades.',
    action: 'Mejorar plan.',
    upgradePlan: 'Mejorar plan',
  },
  signAsStep: {
    title: 'Firmar como',
    cancel: 'Cancelar',
    confirm: 'Agregar',
    label: 'Firmar como',
    refusal: 'Permitir rechazar la firma',
    rubric: 'Confirmación de lectura',
    placeholder: 'Selecciona cómo firmará el firmante',
    authAs: 'Autenticar usando',
    error:
      '{signAs} ya está siendo usada para este firmante | {signAs} ya están siendo usadas para este firmante',
    filterPlaceholder: 'Busca por tipo de firma',
  },
  messageStep: {
    sequenceEnabled: {
      title: 'Mensaje y envío',
      cancel: 'Cancelar',
      confirm: 'Agendar envío',
    },
    title: 'Mensaje y envío',
    cancel: 'Cancelar',
    confirm: 'Enviar documento',
    signer: 'Firmante',
    signAs: 'Forma de firma',
    message: {
      description: 'Si recibes una respuesta al mensaje, será enviado a tu correo electrónico',
    },
    alert: {
      whatsappDisable: 'Mensaje deshabilitado para firmas por WhatsApp',
      whatsappWarning:
        'El siguiente mensaje solo se enviará a quienes firmen a través de correo electrónico.',
    },
    signers: 'Firmantes',
    abort: {
      title: '¿Cancelar envío?',
      sure: '¿Estás seguro de que deseas cancelar?',
      info: 'Este firmante no será agregado a la lista de firmas. | Estos firmantes no serán agregados a la lista de firmas.',
      cancel: 'No, mantener',
      confirm: 'Sí, cancelar envío',
    },
  },
  clausesStep: {
    confirm: 'Avanzar',
    cancel: 'Cancelar',
    title: 'Firma electrónica',
    document: 'Documento',
    selectedSigners: 'Firmante seleccionado: | Firmantes seleccionados:',
    clause: 'Cláusula',
    signersReminder:
      'El siguiente firmante deberá firmar todas las cláusulas que selecciones | Los siguientes firmantes deberán firmar todas las cláusulas que selecciones',
    label: '¿Requieres que el firmante confirme que ha leído el documento?',
  },
  communicateBy: {
    email: 'Correo electrónico',
    whatsapp: 'WhatsApp',
    sms: 'SMS',
  },
  authsKind: {
    email: 'Token vía correo electrónico',
    sms: 'Token vía SMS',
    pix: 'Pix (CPF obligatorio)',
    icpBrasil: 'Certificado Digital (CPF obligatorio)',
    whatsapp: 'Token vía WhatsApp',
    handwritten: 'Firma manuscrita',
    liveness: 'Selfie dinámica',
    officialDocument: 'Documento oficial',
    facialBiometrics: 'Biometría facial (CPF obligatorio)',
    selfie: 'Selfie con documento',
    addressProof: 'Comprobante de domicilio',
    biometric: 'Biometría facial - CAF y SERPRO',
  },
  auths: {
    api: 'API',
    handwritten: 'Firma manuscrita',
    liveness: 'Selfie dinámica',
    officialDocument: 'Documento oficial',
    selfie: 'Selfie con documento',
    email: 'Correo electrónico',
    sms: 'SMS',
    pix: 'Pix',
    icpBrasil: 'Certificado digital',
    whatsapp: 'WhatsApp',
    facialBiometrics: 'Biometría facial (CPF obligatorio)',
    addressProof: 'Comprobante de domicilio',
    biometric: 'Biometría facial - CAF y SERPRO',
    documentscopy: 'Documentoscopia',
  },
  signaturesKind: {
    sign: 'Firmar',
    witness: 'Testigo',
    party: 'Parte',
    intervening: 'Interventor',
    receipt: 'Firmar para confirmar acuse de recibo',
    approve: 'Firmar para aprobar',
    endorser: 'Endosante',
    endorsee: 'Endosatario',
    administrator: 'Administrador',
    guarantor: 'Avalista',
    transferor: 'Cedente',
    transferee: 'Cesionario',
    contractee: 'Contratado',
    contractor: 'Contratante',
    jointDebtor: 'Deudor solidario',
    issuer: 'Emisor',
    manager: 'Gestor',
    buyer: 'Parte compradora',
    seller: 'Parte vendedora',
    attorney: 'Apoderado',
    legalRepresentative: 'Representante legal',
    coResponsible: 'Responsable solidario',
    validator: 'Validador',
    surety: 'Fiador',
    lessor: 'Arrendador',
    lessee: 'Arrendatario',
    ratify: 'Firmar para homologar',
    employer: 'Empleador',
    employee: 'Empleado',
    consenting: 'Anuente',
    accountant: 'Contador',
    debtor: 'Deudor',
    franchisee: 'Franquiciado',
    creditor: 'Acreedor',
    franchisor: 'Franquiciante',
    insured: 'Asegurado',
    grantor: 'Otorgante',
    president: 'Presidente',
    partner: 'Socio',
    comforter: 'Comodante',
    accountHolder: 'Titular de cuenta',
    distracted: 'Distraído',
    distracting: 'Distrayente',
    lender: 'Prestamista',
    associate: 'Asociado',
    broker: 'Corredor',
    bailee: 'Depositario',
    collateralProvider: 'Garantista',
    pledged: 'Prendado',
    consignee: 'Consignatario',
    lawyer: 'Abogado',
    grantee: 'Beneficiario',
    borrower: 'Prestatario',
    guarantorSpouse: 'Cónyuge del fiador',
    legalGuardian: 'Tutor legal',
    director: 'Director(a)',
    realEstateBroker: 'Corredor de bienes raíces',
    insuranceBroker: 'Corredor de seguros',
    licensor: 'Licenciante',
    licensed: 'Licenciatario',
    serviceProvider: 'Prestador(a) de servicios',
    secured: 'Afianzado',
    consented: 'Consentido',
    interveningGuarantor: 'Garante Interviniente',
    consentingIntervenor: 'Interventor Consentidor',
    surveyor: 'Inspector',
    buildingManager: 'Administrador(a) del edificio',
    intermediary: 'Intermediario(a)',
    condominiumMember: 'Copropietario',
    owner: 'Propietario(a)',
    resident: 'Residente',
    treasurer: 'Tesorero(a)',
    secretary: 'Secretario(a)',
    signedAsSign: 'Firmó',
    signedAsApprove: 'Firmó para aprobar',
    signedAsParty: 'Firmó como parte',
    signedAsWitness: 'Firmó como testigo',
    signedAsIntervening: 'Firmó como interviniente',
    signedAsReceipt: 'Firmó para acusar recibo',
    signedAsEndorser: 'Firmó como endosante',
    signedAsEndorsee: 'Firmó como endosatario',
    signedAsAdministrator: 'Firmó como administrador',
    signedAsGuarantor: 'Firmó como fiador',
    signedAsTransferor: 'Firmó como cedente',
    signedAsTransferee: 'Firmó como cesionario',
    signedAsContractee: 'Firmó como contratado',
    signedAsContractor: 'Firmó como contratante',
    signedAsJointDebtor: 'Firmó como deudor solidario',
    signedAsIssuer: 'Firmó como emisor',
    signedAsManager: 'Firmó como gestor',
    signedAsBuyer: 'Firmó como parte compradora',
    signedAsSeller: 'Firmó como parte vendedora',
    signedAsAttorney: 'Firmó como apoderado',
    signedAsLegalRepresentative: 'Firmó como representante legal',
    signedAsCoResponsible: 'Firmó como responsable solidario',
    signedAsValidator: 'Firmó como validador',
    signedAsSurety: 'Firmó como fiador',
    signedAsLessor: 'Firmó como arrendador',
    signedAsLessee: 'Firmó como arrendatario',
    signedAsRatify: 'Firmó para homologar',
    signedAsEmployer: 'Firmó como empleador',
    signedAsEmployee: 'Firmó como empleado',
    signedAsConsenting: 'Firmó como anuente',
    signedAsAccountant: 'Firmó como contador',
    signedAsDebtor: 'Firmó como deudor',
    signedAsFranchisee: 'Firmó como franquiciado',
    signedAsCreditor: 'Firmó como acreedor',
    signedAsFranchisor: 'Firmó como franquiciante',
    signedAsInsured: 'Firmó como asegurado',
    signedAsGrantor: 'Firmó como otorgante',
    signedAsPresident: 'Firmó como presidente',
    signedAsPartner: 'Firmó como socio',
    signedAsComforter: 'Firmó como comodante',
    signedAsAccountHolder: 'Firmó como titular de cuenta',
    signedAsDistracted: 'Firmó como distraído',
    signedAsDistracting: 'Firmó como distrayente',
    signedAsLender: 'Firmó como prestamista',
    signedAsAssociate: 'Firmó como asociado',
    signedAsBroker: 'Firmó como corredor',
    signedAsBailee: 'Firmó como depositario',
    signedAsCollateralProvider: 'Firmó como garantista',
    signedAsPledged: 'Firmó como prendado',
    signedAsConsignee: 'Firmó como consignatario',
    signedAsLawyer: 'Firmó como abogado',
    signedAsGrantee: 'Firmó como beneficiario',
    signedAsBorrower: 'Firmó como prestatario',
    signedAsGuarantorSpouse: 'Firmó como cónyuge del fiador',
    signedAsLegalGuardian: 'Firmó como tutor legal',
    signedAsDirector: 'Firmó como director(a)',
    signedAsRealEstateBroker: 'Firmó como corredor de bienes raíces',
    signedAsInsuranceBroker: 'Firmó como corredor de seguros',
    signedAsLicensor: 'Firmó como licenciante',
    signedAsLicensed: 'Firmó como licenciatario',
    signedAsServiceProvider: 'Firmó como prestador(a) de servicios',
    signedAsSecured: 'Firmó como afianzado',
    signedAsConsented: 'Firmó como consentido',
    signedAsInterveningGuarantor: 'Firmó como garante interviniente',
    signedAsConsentingIntervenor: 'Firmó como interventor consentidor',
    signedAsSurveyor: 'Firmó como inspector',
    signedAsBuildingManager: 'Firmó como administrador(a) del edificio',
    signedAsIntermediary: 'Firmó como intermediario(a)',
    signedAsCondominiumMember: 'Firmó como copropietario',
    signedAsOwner: 'Firmó como propietario(a)',
    signedAsResident: 'Firmó como residente',
    signedAsTreasurer: 'Firmó como tesorero(a)',
    signedAsSecretary: 'Firmó como secretario(a)',
  },
  documents: {
    refused: 'Firmas rechazadas',
    pending: 'Pendientes de firma',
    signed: 'Firmados',
    canceled: 'Cancelados',
    notSigned: 'No firmados',
  },
  editSigner: {
    title: 'Editar firmante',
    signAs: 'Firmar como',
  },
  blockedByManager: '(No disponible en tu cuenta)',
};

export default signer;
