import axios from '@/plugins/axios';
import {
  parseEditingSessionToSettings,
  parseEnvelopeCompatToSettings,
  updateSettingsEditingSession,
  updateSettingsEnvelopeCompat,
  registerOperation,
  fetchMessageEditingSession,
  fetchMessageEnvelopeCompat,
} from '@envelope/services/setup';

export default {
  async fetchSettings({ commit, getters }) {
    try {
      const { data } = await axios.get(getters.getLinks.settings);

      const dataParser = getters.isEditingSession
        ? parseEditingSessionToSettings
        : parseEnvelopeCompatToSettings;

      const settings = dataParser(data);

      commit('SET_SETTINGS', settings);
      return [null, settings];
    } catch (error) {
      return [error, null];
    }
  },

  async updateSettings({ commit, getters }, payload) {
    try {
      const settingsUpdater = getters.isEditingSession
        ? updateSettingsEditingSession
        : updateSettingsEnvelopeCompat;

      const settings = await settingsUpdater({
        links: getters.getLinks,
        settings: { ...payload, defaultMessage: getters.getSettings.defaultMessage },
      });

      commit('SET_SETTINGS', settings);
      return [null, settings];
    } catch (error) {
      if (!getters.isEditingSession && error.response?.data) {
        return [error.response.data.pack, null];
      }
      return [error, null];
    }
  },
  async savePositionSigns({ getters }, payload) {
    const links = getters.getLinks;

    await Promise.all(
      payload?.map(async (signature) => {
        return registerOperation(
          { links, data: signature, name: 'setup/add_rubricate' },
          { jsonRootKey: 'setupSession' }
        );
      })
    );
  },
  async fetchMessage({ getters, commit }) {
    try {
      const messageFetcher = getters.isEditingSession
        ? fetchMessageEditingSession
        : fetchMessageEnvelopeCompat;

      const { message, subject } = await messageFetcher({
        links: getters.getLinks,
      });

      commit('SET_MESSAGE', message);

      if (subject) {
        commit('SET_SUBJECT', subject);
      }

      return [null, message];
    } catch (error) {
      return [error, null];
    }
  },
  async fetchIntelligentMessage({ rootGetters, commit }) {
    try {
      const link = rootGetters['draft/getLinks'].suggestionTexts;
      const { data } = await axios.post(link);

      commit('SET_MESSAGE', data.message);
      commit('SET_SUBJECT', data.subject);

      return [null, { message: data.message, subject: data.subject }];
    } catch (error) {
      return [error, null];
    }
  },
  async updateSubject({ getters, commit }, payload) {
    try {
      const messageUpdater = updateSettingsEnvelopeCompat;

      await messageUpdater({
        links: getters.getLinks,
        settings: {
          ...getters.getSettings,
          defaultSubject: payload,
        },
      });

      commit('SET_SUBJECT', payload);

      return [null, true];
    } catch (error) {
      return [error, null];
    }
  },
  async updateMessage({ getters, commit }, payload) {
    try {
      const messageUpdater = getters.isEditingSession
        ? updateSettingsEditingSession
        : updateSettingsEnvelopeCompat;

      await messageUpdater({
        links: getters.getLinks,
        settings: {
          ...getters.getSettings,
          defaultMessage: payload,
        },
      });

      commit('SET_MESSAGE', payload);

      return [null, true];
    } catch (error) {
      return [error, null];
    }
  },

  async createSignatureWatcher({ rootGetters, getters }, payload) {
    const link =
      getters.getLinks.signatureWatchers || rootGetters['draft/getLinks'].signatureWatchers;
    await axios.post(link, payload);
  },

  async fetchSignatureWatcher({ rootGetters, commit, getters }) {
    const link =
      getters.getLinks.signatureWatchers || rootGetters['draft/getLinks'].signatureWatchers;
    const {
      data: { signatureWatchers },
    } = await axios.get(link);

    commit('SET_SIGNATURE_WATCHERS', signatureWatchers);
  },

  async updateSignatureWatcher(_, { link, attachDocumentsEnabled }) {
    await axios.put(link, { attachDocumentsEnabled });
  },

  async deleteSignatureWatcher(_, link) {
    await axios.delete(link);
  },
};
