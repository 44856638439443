import rubric from './rubric';

const draft = {
  errorWhenSendEnvelope: 'Houve um problema no envio, tente novamente.',
  wizard: {
    addDocuments: 'Adicionar documentos',
    addSigners: 'Adicionar signatários',
    rubric: 'Rubrica eletrônica',
    position: 'Posicionamento',
    settings: 'Configurações',
    message: 'Mensagem e envio',
    settingsTooltip: {
      title: 'Para facilitar, deixamos tudo pronto por aqui.',
      content: 'Você pode alterar suas configurações de envio, se quiser.',
      action: 'Entendi',
    },
  },
  uploadArchives: {
    title: 'Documentos',
    dragAndDrop: 'Arrastar e soltar aqui',
    dragAndDropShort: 'Selecionar arquivos',
    or: 'ou',
    select: 'Selecionar do computador',
    fromComputer: 'Do computador...',
    fromDrive: 'Do Google Drive...',
    doubtsHelp: 'Dúvidas sobre o envio',
    actualFolder: 'Pasta atual:',
    acceptedTypes: 'Formatos aceitos: .pdf, .docx, .txt, .png, .jpg e .jpeg',
    sizeLimit:
      'Tamanho máximo do arquivo: {docLimit}Mb para .docx e pdf e {imgLimit}Mb para .txt, .png, .jpg e .jpeg',
    googleDriveSizeLimit: 'Tamanho máximo do arquivo: {limit}Mb',
    unlimitedPlan: 'Seu plano permite {limit} documento por envio',
    limitQuantity: 'Limite máximo de envio: {limit} documentos',
    changeFolder: 'Alterar pasta',
    errorFolder: 'Sem acesso à pasta',
    errorFolderTip:
      'Ela pode ter sido excluída, movida para lixeira ou você não tem permissão de acesso',
    chooseNewFolder: 'Escolher nova pasta',
    defaultError: 'Erro ao fazer upload de um ou mais arquivos',
    googleDrive: {
      select: 'Selecionar do Google Drive',
      errors: {
        permission: 'Sem permissão para acesso. Por favor, selecione outro arquivo.',
        default: 'Erro ao processar o arquivo. Tente novamente.',
      },
    },
  },
  folderSelection: {
    title: 'Pasta',
    action: 'Selecionar pasta',
    restrictedFolder: '(pasta restrita)',
    publicFolder: '(visível para todos)',
  },
  deleteArchives: {
    defaultError: 'Erro ao remover um ou mais arquivos',
  },
  modalSelectFolder: {
    header: 'Selecionar Pasta',
    description: 'Selecione onde os documentos serão criados.',
    newFolderTip: 'Após selecionar uma pasta, você poderá criar uma nova.',
  },
  archives: {
    uploading: 'Carregando documento...',
    loadingPreview: 'Carregando visualização...',
    emptyList: 'Visualize documentos para assinatura aqui.',
    updatingDocuments: 'Atualizando lista de documentos',
    deleteDocumentDisabled: 'Não é possível excluir um documento em processo',
    envelopeList: {
      uploading: 'Carregando...',
      loadingPreview: 'Processando...',
      uploadSuccess: 'Upload concluído',
      seeMore: 'Ver mais {count} documento | Ver mais {count} documentos',
      seeLess: 'Exibir menos',
    },
    actions: {
      cancel: 'Cancelar documento',
      delete: 'Excluir documento',
    },
  },
  signatures: {
    title: 'Adicionar signatários',
    archivesList: 'Documentos',
    loadingPreview: 'Carregando visualização...',
    preview: 'Visualizar',
    showAll: 'Ver todos',
    showLess: 'Ver menos',
    empty: 'Adicione um {0} ou um {1}',
    emptyShort: 'Clique em {0} para adicionar um signatário ao seu documento',
    newSigners: 'Signatário novo',
    signersContact: 'Signatário da agenda',
    sequenceEnabled: {
      alert: 'Ordem das assinaturas ativada. Não será possível modificar após o envio.',
      link: 'Veja como funciona',
      signAllDocuments: 'Assina todos os documentos',
      tooltip: 'Desative a opção "Ordernar assinaturas" para alterar',
    },
    blockOrderingAfterSend: 'Não será possível ordenar assinaturas após o envio.',
    autoSign: {
      text: 'Assinar automaticamente em meu nome',
      badge: 'Novo',
    },
    orphanDocuments:
      'Vincule pelo menos um signatário ao documento abaixo: | Vincule pelo menos um signatário aos documentos abaixo:',
    orderingAlert: 'Todos os signatários assinarão todos os documentos.',
    noDocumentsSelectedError: 'Selecione pelo menos um documento',
    blockOrderingModal: {
      title: 'Ordenação indisponível',
      orderingCondition:
        'A ordenação só pode ser ativada nos casos em que todos os signatários assinam todos os documentos.',
      actionRequirement: 'Edite a lista de assinatura para usar este recurso.',
    },
    deleteSignerModal: {
      title: 'Remover signatário da lista?',
      description: 'O signatário será excluído da lista de assinaturas deste envio.',
    },
    setup: {
      title: 'Signatários',
    },
  },
  envelope: {
    archivesList: 'Envelope',
    help: 'Saiba mais sobre envelope',
    message: {
      send: 'Enviar',
    },
    success: {
      seeEnvelope: 'Ver envelope',
    },
    tooltipSettings: {
      title: 'Posicionamento de assinatura, datas limite e outras opções',
      configuredForYou: 'Configuramos tudo para você.',
      configSaved: 'Suas configurações serão lembradas nos próximos envios.',
    },
  },
  settings: {
    language: 'idioma',
    closeDocument: 'finalização',
    closeDocumentInfo: 'Quando os documentos enviados devem ser finalizados?',
    autoClose: 'Após todos assinarem, automaticamente.',
    manualClose: 'Manualmente ou até a data limite. Assim é possível revisar antes de finalizar.',
    deadlineDate: 'DATA LIMITE PARA ASSINATURAS',
    deadlineDateInfo: 'Assinaturas não poderão ser efetuadas após atingida a data limite.',
    autoReminders: 'LEMBRETES AUTOMÁTICOS',
    autoRemindersInfo:
      'Envio de até 3 lembretes por e-mail para signatários que ainda não assinaram.',
    sendReminders: 'Enviar lembretes automaticamente a cada',
    documentLanguage: 'IDIOMA DO DOCUMENTO',
    deadlineError: 'Deve ser posterior a data de hoje',
    documentLanguageInfo:
      'A página de assinatura e os e-mails serão exibidos no idioma selecionado.',
    deadlineTooltip:
      'Quando a data limite do documento for atingida, o documento será finalizado automaticamente. Caso não conste nenhuma assinatura ou existam assinaturas recusadas, ele será cancelado. | Quando a data limite dos documentos for atingida, os documentos serão finalizados automaticamente. Caso não conste nenhuma assinatura ou existam assinaturas recusadas, eles serão cancelados.',
    english: 'Inglês (EUA)',
    portuguese: 'Português',
    modalDeadline: {
      header: 'Data limite para assinaturas',
      info: 'Deseja manter a data atual?',
      keep: 'Manter',
      change: 'Alterar',
    },
    blockOnRefusal: {
      title: 'PAUSAR ASSINATURA APÓS A RECUSA',
      tooltip: 'Os demais signatários não poderão assinar quando alguém recusar a assinatura',
      checkbox: 'Pausar o processo de assinatura quando um signatário recusar',
    },
  },
  premium: {
    text: 'e envie mais de um documento por vez.',
    action: 'Altere seu Plano',
  },
  message: {
    titleNew: 'Mensagem',
    title: 'Mensagem e envio',
    uploading: 'Carregando documento... | Carregando documentos...',
    info: 'Esta mensagem irá para todos os signatários. Se a mensagem for respondida, será encaminhada diretamente para o seu e-mail.',
    send: 'Enviar documento | Enviar {count} documentos',
  },
  success: {
    ready: 'Pronto!',
    envelopeSentToSigners: 'Seus documentos serão enviados para assinatura!',
    documentSentToSigners:
      'Seu documento será enviado para assinatura! | Seus documentos serão enviados para assinatura!',
    seeDocument: 'Acompanhar documento | Ver listagem de documentos',
  },
  list: {
    drafts: 'Rascunhos',
    expirationWarning: 'Os rascunhos ficam disponíveis por 7 dias.',
    signers: 'Nenhum signatário adicionado | Signatário | Signatários',
    actions: {
      selectAll: 'Selecionar tudo',
    },
    status: 'Atualizado em {at}',
    showMoreDocuments: 'Ver mais documentos',
    showLessDocuments: 'Ver menos documentos',
    showMoreSigners: 'Ver mais signatários',
    showLessSigners: 'Ver menos signatários',
    modalDelete: {
      header: 'Deseja excluir o rascunho? | Deseja excluir os rascunhos?',
      info: 'Tem certeza que deseja excluir {count} rascunho? | Tem certeza que deseja excluir {count} rascunhos?',
      hasTrialLimit:
        'Este rascunho já foi contabilizado como um dos 30 envios gratuitos do período de teste. O saldo de envios não será alterado se você excluí-lo. | Estes rascunhos já foram contabilizados como parte dos 30 envios gratuitos do período de teste. O saldo de envios não será alterado se você excluí-los.',
    },
    emptyDocuments: 'Nenhum documento adicionado',
    successfulDeleted: 'Rascunho excluído | Rascunhos excluídos',
    empty: 'Os documentos ainda não enviados para assinatura serão exibidos aqui',
  },
  preview: {
    download: 'Baixar',
    page: 'Página',
  },
  rubric,
  firstSendingSuccess: {
    title: 'Você enviou o seu primeiro documento!',
    description: 'E ainda ganhou segurança, reduziu custos e economizou tempo',
    goToDocument: 'Acompanhar documento',
    goToDashboard: 'Ir para o início',
  },
  selfSignerSuccess: {
    text: 'Deseja assinar o documento agora?',
    actions: {
      followEnvelope: 'Acompanhar envelope',
      sendAnother: 'Enviar outro',
      seeToSign: 'Visualizar para assinar',
    },
  },
  alreadySigned: {
    text: 'Os documentos foram assinados por você.',
  },
  watcher: {
    title: 'Observadores',
    button: 'Adicionar',
    email: {
      placeholder: 'Digite o e-mail',
      label: 'Quem vai acompanhar a assinatura',
    },
    kind: {
      label: 'Quando será notificado',
      options: {
        allSteps: 'A cada assinatura do documento',
        onFinished: 'Somente na finalização do documento',
      },
    },
    item: {
      allSteps: 'Será notificado a cada assinatura do documento',
      onFinished: 'Será notificado somente na finalização do documento',
      checkbox: 'Receber o documento finalizado',
    },
  },
};

export default draft;
