<script setup>
import { useStore } from '@/store';
import { computed, ref } from 'vue';

const emit = defineEmits(['close', 'create']);

defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const name = ref('');
const isCreateButtonEnabled = computed(() => name.value.length > 0);

const selectedFlows = computed(() => store.getters['flowFolder/getSelectedFlows']);

const closeModal = () => {
  emit('close');
  store.dispatch('flowFolder/setSelectedFlows', []);
  name.value = '';
};
</script>

<template>
  <XModal
    :is-open="showModal"
    :title="$t('flowModule.folders.create.modal.title')"
    data-testid="formFlowCreateFolderModal"
    @close="closeModal"
  >
    <template #default>
      <div :class="$style.body">
        <p v-if="selectedFlows.length > 0">
          {{ $t('flowModule.folders.create.modal.selectedFlows') }}
        </p>
        <XTextInput
          v-model="name"
          :placeholder="$t('flowModule.folders.create.modal.placeholder')"
          data-testid="formFlowCreateFolderModalNameInput"
          full-width
        />
      </div>
    </template>
    <template #actions>
      <div :class="$style.actions">
        <XButton
          design="outlined"
          data-testid="formFlowCreateFolderModalCancelButton"
          @click="closeModal"
        >
          {{ $t('general.action.cancel') }}
        </XButton>
        <XButton
          data-testid="formFlowCreateFolderModalCreateButton"
          :disabled="!isCreateButtonEnabled"
          @click="$emit('create', { name })"
        >
          {{ $t('general.action.create') }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.body {
  padding-top: var(--space-medium);
}

.actions {
  display: flex;
  gap: var(--space-small-xx);
}
</style>
