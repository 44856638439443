import axios from 'axios';
// TODO: Padronizar nomes das actions

const getAccountFlow = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'get',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}`,
    });

    const payload = response.data.flow;
    commit('GET_FLOW', payload);
    return payload;
  } catch (error) {
    throw error;
  }
};

// TODO: Mover actions sem commits para services ou outra solução
/* eslint-disable */
const updateAccountFlow = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'patch',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}`,
      data: obj.flow,
    });

    const payload = response;
    return payload;
  } catch (error) {
    throw error;
  }
};
/* eslint-enable */

const setFormFlow = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}/set_form_builder?enable_second_form=${obj.enableSecondForm}`,
      data: {
        flow: {
          form_builder_id: obj.formId,
          order: obj.order,
        },
      },
    });

    const payload = response.data.flow;

    if (obj.order === 1) {
      commit('SET_FORM_FLOW_ONE', payload);
    } else {
      commit('SET_FORM_FLOW_TWO', payload);
    }

    commit('GET_FLOW', payload);
    commit('GET_FIELDS_MAPPINGS_FLOW', payload);

    return payload;
  } catch (error) {
    throw error;
  }
};

const updateTemplateFlow = async ({ commit }, obj) => {
  try {
    const { data } = await axios({
      method: 'patch',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}`,
      data: { template_id: obj.templateId },
    });

    const payload = obj.templateId;

    commit('GET_FLOW', data.flow);
    commit('UPDATE_TEMPLATE_FLOW', payload);
    commit('GET_FIELDS_MAPPINGS_FLOW', data.flow);

    return payload;
  } catch (error) {
    throw error;
  }
};

const getFlowFieldsMappings = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'get',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}`,
    });

    const payload = response.data.flow;

    commit('GET_FIELDS_MAPPINGS_FLOW', payload);
    return payload;
  } catch (error) {
    throw error;
  }
};

const updateFlowFieldsMappings = async ({ commit }, obj) => {
  try {
    await axios({
      method: 'post',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}/fields_mappings`,
      data: { mappings: obj.fieldsMappings },
    });

    const payload = obj.fieldsMappings;

    commit('UPDATE_FIELDS_MAPPINGS_FLOW', payload);

    return payload;
  } catch (error) {
    throw error;
  }
};

const getSignersFlow = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'get',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}/signers_list`,
    });

    const payload = response.data.signers_list;
    commit('GET_SIGNERS_FLOW', payload);
    return payload;
  } catch (error) {
    throw error;
  }
};

const deleteFlowSigner = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}/lists/${obj.id}`,
    });

    const payload = response.data;
    commit('DELETE_SIGNER_FLOW', payload);
    return payload;
  } catch (error) {
    throw error;
  }
};

const deleteFlowFormSigner = async (_, payload) => {
  try {
    await axios({
      method: 'delete',
      url: `/accounts/${payload.accountId}/flows/${payload.flowId}/form_lists/${payload.id}`,
      params: {
        update_form_builder: payload.updateFormBuilder,
      },
    });
  } catch (error) {
    throw error;
  }
};

const updateFlowFolder = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'patch',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}`,
      data: obj,
    });

    const payload = {
      document_name: response.data.flow.document_name,
      path: response.data.flow.path,
    };

    commit('UPDATE_FOLDER_FLOW', payload);
    return payload;
  } catch (error) {
    throw error;
  }
};

// TODO: Mover actions sem commits para services ou outra solução
/* eslint-disable */
const setFlowDocument = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/fluxia/${obj.flow_key}`,
      data: obj,
    });

    const payload = response;

    return payload;
  } catch (error) {
    throw error;
  }
};
/* eslint-enable */

const updateEnabledFlow = async ({ commit }, obj) => {
  try {
    await axios({
      method: 'patch',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}`,
      data: { flow: obj.flow },
    });

    const payload = true;
    commit('UPDATE_ENABLED_FLOW', payload);
    return payload;
  } catch (error) {
    throw error;
  }
};

const updateMessageFlow = async ({ commit }, obj) => {
  try {
    await axios({
      method: 'patch',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}`,
      data: { flow: obj.flow },
    });

    const payload = obj.flow.message;
    commit('UPDATE_MESSAGE_FLOW', payload);
    return payload;
  } catch (error) {
    throw error;
  }
};

const updateSettingsFlow = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'patch',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}`,
      data: { flow: obj.flow },
    });

    const payload = response.data.flow;
    commit('UPDATE_SETTINGS_FLOW', payload);
    return payload;
  } catch (error) {
    throw error;
  }
};

// TODO: Mover actions sem commits para services ou outra solução
/* eslint-disable */
const getFlowOrdering = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}/sequencing`,
    });

    const payload = {
      sequence_enabled: response.data.sequence_enabled,
      groups: response.data.groups,
    };
    return payload;
  } catch (error) {
    throw error;
  }
};
/* eslint-enable */

const setFlowOrdering = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/accounts/${obj.accountId}/flows/${obj.flowId}/configure_sequencing`,
      data: obj.config,
    });

    const payload = {
      sequence_enabled: obj.config.sequence_enabled,
      groups: response.data.groups,
    };
    commit('FLOW_ORDERING', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default {
  getAccountFlow,
  updateAccountFlow,
  setFormFlow,
  updateTemplateFlow,
  getFlowFieldsMappings,
  updateFlowFieldsMappings,
  updateFlowFolder,
  getSignersFlow,
  deleteFlowSigner,
  deleteFlowFormSigner,
  setFlowDocument,
  updateEnabledFlow,
  updateSettingsFlow,
  updateMessageFlow,
  getFlowOrdering,
  setFlowOrdering,
};
