<script setup>
import { computed, ref, watch } from 'vue';
import { useInfiniteScroll, useDebounceFn } from '@vueuse/core';
import { useStore } from '@/store';

defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});
defineEmits(['close', 'handle-move']);

const MIN_TEXT_LENGTH = 3;
const INFINITE_SCROLL_DISTANCE = 120;
const SEARCH_DEBOUNCE_DELAY = 400;

const store = useStore();

const loading = ref(true);
const listWrapperEl = ref(null);
const search = ref('');
const selectedFlowIds = ref([]);

const formFlowList = computed(() => store.getters['flowFolder/getUnassignedFlows']);
const currentPage = computed(() => store.getters['flowFolder/getUnassignedFlowsPagination'].page);
const totalPages = computed(
  () => store.getters['flowFolder/getUnassignedFlowsPagination'].pageCount
);
const totalSelected = computed(() => selectedFlowIds.value.length);
const isMoveButtonEnabled = computed(() => totalSelected.value > 0);
const links = computed(() => store.getters['info/getLinks']);
const isLastPage = computed(
  () => totalPages.value !== null && currentPage.value >= totalPages.value
);

const fetchFlowList = async () => {
  loading.value = true;
  const flowName = search.value.trim();
  await store.dispatch('flowFolder/fetchUnassignedFlows', { flowName });
  loading.value = false;
};

const openedModal = () => {
  selectedFlowIds.value = [];
  search.value = '';
  store.dispatch('flowFolder/resetUnassignedFlows');
  fetchFlowList();
};

const skipSearch = (textSearch) => {
  const trimmed = textSearch.trim();
  const textSearchIsEmpty = trimmed.length === 0;

  return trimmed.length < MIN_TEXT_LENGTH && !textSearchIsEmpty;
};

const handleSearch = async () => {
  if (skipSearch(search.value)) return;

  store.dispatch('flowFolder/resetUnassignedFlows');
  fetchFlowList();
};

const toggleFlowSelection = (flowId) => {
  if (selectedFlowIds.value.includes(flowId)) {
    selectedFlowIds.value = selectedFlowIds.value.filter((id) => id !== flowId);
  } else {
    selectedFlowIds.value = [...selectedFlowIds.value, flowId];
  }
};

const debouncedSearch = useDebounceFn(handleSearch, SEARCH_DEBOUNCE_DELAY);

useInfiniteScroll(
  listWrapperEl,
  () => {
    if (loading.value || isLastPage.value) return;

    fetchFlowList();
  },
  { distance: INFINITE_SCROLL_DISTANCE }
);

watch(search, debouncedSearch);
</script>

<template>
  <XModal
    :is-open="showModal"
    :title="$t('flowModule.folders.searchExistingFlows.modal.title')"
    data-testid="searchExistingFlowsModal"
    @opened="openedModal"
    @close="$emit('close')"
  >
    <template #default>
      <p>{{ $t('flowModule.folders.searchExistingFlows.modal.description') }}</p>
      <XTextInput
        v-model="search"
        :class="$style.search"
        :placeholder="$t('flowModule.folders.searchExistingFlows.modal.placeholder')"
        full-width
        grouped
        data-testid="searchExistingFlowsModalTextSearch"
      >
        <XButton
          model="plain"
          @click="handleSearch"
        >
          <XIcon icon="search" />
        </XButton>
      </XTextInput>
      <div
        ref="listWrapperEl"
        :class="$style.listWrapper"
        data-testid="unassignedFlowList"
      >
        <XListItem
          v-for="(flow, index) in formFlowList"
          :key="`flow-${flow.id}-${index}`"
          :active="selectedFlowIds.includes(flow.id)"
          checkbox
          ellipsis
          :data-testid="`flow-${flow.id}-${index}`"
          @click="toggleFlowSelection(flow.id)"
        >
          {{ flow.name }}
        </XListItem>
        <template v-if="loading || !isLastPage">
          <XListItem
            v-for="index in 3"
            :key="index"
            no-clickable
            height="40px"
          >
            <XSkeletonLoader
              height="24px"
              shape="rounded"
            />
          </XListItem>
        </template>
        <template v-else-if="formFlowList.length === 0">
          <div
            :class="$style.empty"
            data-testid="searchExistingFlowsModalEmptyView"
          >
            <XIcon
              icon="search"
              size="2x"
            />
            <h4 :class="$style.title">
              {{ $t('flowModule.folders.searchExistingFlows.modal.empty') }}
            </h4>
          </div>
        </template>
      </div>
    </template>
    <template #actions>
      <div :class="$style.actions">
        <XButton
          design="outlined"
          data-testid="searchExistingFlowsModalGoToFlowsButton"
          :to="links.flows"
        >
          {{ $t('flowModule.folders.searchExistingFlows.modal.goToFlows') }}
        </XButton>
        <XButton
          data-testid="searchExistingFlowsModalMoveButton"
          :disabled="!isMoveButtonEnabled"
          @click="$emit('handle-move', { flowFormIds: selectedFlowIds })"
        >
          {{
            $tc('flowModule.folders.searchExistingFlows.modal.move', totalSelected, {
              count: totalSelected,
            })
          }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.search {
  margin-bottom: var(--space-small-xx);
}

.listWrapper {
  overflow-y: auto;
  height: 200px;
  border: var(--border-width-small) solid var(--color-neutral-400);
  border-radius: var(--border-radius-medium);

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: var(--space-regular);
  }
}

.actions {
  display: flex;
  gap: var(--space-small-xx);
}
</style>
