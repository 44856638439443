export default {
  getLinks: (state) => state.links,
  getFolder: (state) => state.folder,
  getFolders: (state) => state.folders,
  getPagination: (state) => state.pagination,
  getTotalItems: (state) => state.totalItems,
  getUnassignedFlows: (state) => state.unassignedFlows,
  getUnassignedFlowsPagination: (state) => state.unassignedFlowsPagination,
  getSelectedFolder: (state) => state.selectedFolder,
  getSelectedFlows: (state) => state.selectedFlows,
};
