import axios from '@/plugins/axios';

export async function fetchMessageEditingSession({ links }) {
  const { data } = await axios.get(links.settings);

  return data.envelope.defaultMessage;
}

export async function fetchMessageEnvelopeCompat({ links }) {
  const { data } = await axios.get(links.settings);

  return {
    message: data.pack.defaultMessage,
    subject: data.pack.defaultSubject,
  };
}
