<script setup>
import { ref, onMounted } from 'vue';
import lottieLight from 'lottie-web/build/player/lottie_light';
import animationPulse from '@operators/assets/animations/pulse.json';
import animationPulseError from '@operators/assets/animations/pulse-error.json';

const lottieAnimation = ref(null);

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  iconStyle: {
    type: String,
    default: 'fad',
  },
  iconSize: {
    type: String,
    default: '3x',
  },
  status: {
    type: String,
    default: 'default',
  },
});

const animationStatus = {
  default: animationPulse,
  error: animationPulseError,
};

const startAnimation = () => {
  lottieLight.loadAnimation({
    container: lottieAnimation.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: animationStatus[props.status],
  });
};

onMounted(startAnimation);
</script>

<template>
  <div
    ref="lottieAnimation"
    data-testid="svgContainer"
    :class="$style.lottieAnimation"
  >
    <XIcon
      :icon-style="iconStyle"
      :size="iconSize"
      data-testid="iconPulse"
      :class="[$style.icon, $style[status]]"
      :icon="icon"
    />
  </div>
</template>

<style module lang="scss">
.lottieAnimation {
  position: relative;
  display: flex;
  align-items: center;
  width: 120px;
  height: 120px;

  .icon {
    position: absolute;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;

    &.default {
      color: var(--color-brand-primary-400);
    }

    &.error {
      color: var(--color-feedback-error-400);
    }
  }
}
</style>
