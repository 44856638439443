const dashboard = {
  lastUpdate: 'Última actualización el {date} a las {time}.',
  reload: 'Actualizar',
  export: 'Exportar',
  unconfirmedEmail:
    'Confirma tu correo electrónico para enviar documentos para firma. Si aún no has recibido el correo de confirmación.',
  unconfirmedEmailLink: 'reenviar.',
  privacy: 'Política de privacidad',
  terms: 'Términos de uso',
  trialSubscriptionAlert: {
    purchase: '¡Elige el mejor plan para ti y contrátalo ahora!',
    expired: 'Tu prueba ha expirado',
    subscribe: 'Consultar los planes',
  },
  widget: {
    sendDocument: {
      title: 'Nueva firma',
      send: 'Agregar documentos',
      allowedType: 'Formatos aceptados: .pdf y .docx',
      download: 'Descargar plantilla',
      downloadInfo: 'Descarga un documento de ejemplo y agrégalo para probar el envío.',
    },
    notification: {
      title: 'Notificaciones',
      newMessage: '1 nueva notificación | {count} nuevas notificaciones',
      noNotifications: 'Sin novedades por aquí hoy.',
    },
    documentsStatuses: {
      title: 'Documentos',
      link: {
        viewReports: 'Ver informe',
        allDocuments: 'Ver todos los documentos',
      },
      filter: {
        thisMoment: 'En este momento',
        last7Days: 'Últimos 7 días',
        last15Days: 'Últimos 15 días',
        last30Days: 'Últimos 30 días',
      },
      status: {
        running: 'En proceso',
        closed: 'Finalizados',
        canceled: 'Cancelados',
        refused: 'Firma rechazada | Firmas rechazadas',
      },
      emptyState: {
        title: 'No ha enviado un documento para firma en los últimos 30 días',
        text: '¿Qué te parece si hacemos envío?',
        action: 'Ver cómo',
      },
    },
    help: {
      title: '¿Necesitas ayuda?',
      helpCentral: 'Preguntas frecuentes',
      support: 'Contacto con soporte',
      sales: 'Contacto con ventas',
    },
    signaturesOverview: {
      nearToEndDate: 'Próximos a la fecha límite',
      pendentSignature: 'Con una firma pendiente',
      signatureTimeAverage: 'Tiempo promedio de firma',
      signaturePeriodicity: 'día',
    },
    accountUsage: {
      title: 'Consumo del plan',
      plan: 'Plan:',
      changePlan: 'Cambiar mi plan',
      consumptionPage: 'Ver página de consumo',
      currentPeriod: 'Período:',
      periodRange: '{initialDate} a {endDate}',
      remainingDocuments: 'Restantes',
      finishedDocuments: 'Firmados',
      canceledDocuments: 'Cancelados',
      whatsapp: 'WhatsApp',
      sms: 'SMS',
      finishedDocumentsInfo:
        'Los documentos firmados hasta 3 días antes del fin del período entran en el consumo de la cuenta',
      whatsappInfo: 'Disponible solo para los planes Integración y Automatización',
      periodicityMonthly: 'Documentos/mes',
      documents: 'Documentos',
      extras: 'Recursos extra',
      tokenBy: 'Envío de token vía',
      graphLabels: {
        sent: 'Enviados',
        finished: 'Finalizados',
        canceled: 'Cancelados',
      },
    },
  },
  signatureActions: {
    sendDocuments: {
      title: 'Enviar documentos',
      action: 'Agregar documento',
      createAutomation: 'Crear automatización',
      sign: 'Firmar',
    },
    signDocuments: {
      title: 'Firmar documentos',
      action: 'Ingresar al área de firma',
      readonlySubtitle: 'Revisa y firma tus documentos aquí',
      readonlyHelpLink: 'Comprende el área de la firma',
    },
  },
  integrationCta: {
    acquisition: {
      badge: 'Integración',
      title: 'Integrar con Clicksign',
      description:
        'Ahorra tiempo enviando documentos desde tu sistema {0} ¡Conoce y prueba gratis!',
      descriptionHighlight: 'directamente a Clicksign.',
      action: 'Quiero conocer más',
    },
    activate: {
      badge: 'Primeros pasos',
      title: 'Integración con Clicksign',
      description: 'Documentos de {0}',
      descriptionHighlight: 'tu sistema directamente a Clicksign.',
      action: 'Configurar',
      dismiss: 'Descartar',
    },
    upgrade: {
      badge: 'Mejora',
      title: 'Integra con Clicksign',
      description:
        'Ahorra tiempo enviando documentos desde tu sistema {0} ¡Conoce y prueba gratis!!',
      descriptionHighlight: 'directamente a Clicksign.',
      action: 'Quiero conocer',
    },
    activateModal: {
      header: 'Integración con la API Clicksign',
      description: 'Configura y prueba la integración entre tu sistema y Clicksign',
      info: {
        sandbox: {
          title: 'Entorno sandbox',
          description: 'Prueba la conexión con la API de forma gratuita e ilimitada',
        },
        config: {
          title: 'Configuraciones de la API',
          description: 'Crea el token de la cuenta y define el usuario de la API',
        },
        documentation: {
          title: 'Documentación de la API',
          description: 'Conoce y comienza a hacer solicitudes',
        },
      },
      actions: {
        title: '¿Este contenido fue útil?',
        yes: 'Sí',
        no: 'No',
      },
    },
    upgradeModal: {
      header: 'Integración con la API Clicksign',
      title: 'Más eficiencia en tus procesos',
      description:
        'La integración con la API de Clicksign, automatiza tareas, ahorra tiempo y reduce errores.',
      info: {
        whatDoINeed: {
          title: '¿Qué necesito  para realizar la integración??',
          description:
            'Necesitas generar tus documentos a partir de un sistema. Si este es tu caso, ¡tu equipo de desarrolladores puede comenzar a probar la integración ahora mismo!',
        },
        howDoITest: {
          title: '¿Cómo empiezo a probar la integración?',
          description1: 'Tenemos un entorno exclusivo para pruebas con la API, que es el {0}.',
          sandbox: 'Sandbox',
          description2: 'Los primeros pasos para integrar los encuentra en la {0}',
          documentation: 'documentación de la API.',
        },
        howMuch: {
          title: '¿Cuánto cuesta un plan con integración con API?',
          description:
            'Desde R$39 por mes. Y lo mejor: cuanto mayor sea el plan contratado, menor será el precio por documento.',
        },
        howDoIGet: {
          title: '¿Cómo contratar?',
          description: 'Basta con contratar un plan Start, Plus o Avanzado. {0}',
          link: 'Ver todas las opciones aquí.',
          linkOld: 'Completa tu información',
        },
      },
      actions: {
        title: '¿Este contenido fue útil?',
        yes: 'Sí',
        no: 'No',
      },
    },
    dismissActivateCardModal: {
      title: '¿Deseas eliminar el contenido permanentemente?',
      description: 'La información permanecerá disponible en nuestra central de ayuda.',
      actions: {
        cancel: 'Cancelar',
        confirm: 'Eliminar',
      },
      error: 'Error al eliminar contenido de ayuda',
    },
  },
  trialConversionCard: {
    badge: 'Contratar un plan',
    title: 'Gana agilidad y seguridad con la firma electrónica',
    firstText: 'Simplifica tus procesos.',
    secondText: 'Planes desde R$ 39 al mes.',
    action: 'Quiero contratar',
  },
  trustWindowModal: {
    forcedChurn: {
      title: 'Cuenta temporalmente reactivada',
      description:
        'Su factura ya ha sido emitida. Si el pago no se identifica antes de la fecha de vencimiento, la cuenta será bloqueada nuevamente.',
      action: 'Ver factura',
    },
    overduePayments: {
      title: 'Pago en proceso de verificación',
      description: 'Mientras revisamos, puedes continuar usando la plataforma',
      action: 'Entendido',
    },
    lastDay: {
      title: 'Pago aún no identificado',
      description:
        'Tu cuenta será bloqueada nuevamente en 24 horas. Realiza el pago o envía el comprobante para garantizar tu acceso.',
      actions: {
        seeInvoice: 'Ver factura',
        paymentVoucher: 'Enviar comprobante',
      },
    },
  },

  // TOGGLE_CUSTOM_TRIAL_ENABLED
  // TODO: Remove sendDocument, signerArea, documentsUsage, trialCTA and footer objects
  sendDocument: {
    title: '@:dashboard.signatureActions.sendDocuments.title',
    action: '@:dashboard.signatureActions.sendDocuments.action',
  },
  signerArea: {
    title: '@:dashboard.signatureActions.signDocuments.title',
    action: '@:dashboard.signatureActions.signDocuments.action',
  },
  trialCTA: {
    description: '@:dashboard.trialSubscriptionAlert.purchase',
    badge: '@:dashboard.trialConversionCard.badge',
    action: '@:dashboard.trialSubscriptionAlert.subscribe',
  },
  documentUsage: {
    title: '@:dashboard.widget.documentsStatuses.title',
    runtime: {
      title: '@:dashboard.widget.documentsStatuses.filter.thisMoment',
      running: '@:dashboard.widget.documentsStatuses.status.running',
      refused: '@:dashboard.widget.documentsStatuses.status.refused',
    },
    historic: {
      closed: '@:dashboard.widget.documentsStatuses.status.closed',
      canceled: '@:dashboard.widget.documentsStatuses.status.canceled',
    },
    links: {
      documents: '@:dashboard.widget.documentsStatuses.link.allDocuments',
      report: '@:dashboard.widget.documentsStatuses.link.viewReports',
    },
  },
  footer: {
    privacy: '@:dashboard.privacy',
    terms: '@:dashboard.terms',
    reload: '@:dashboard.reload',
  },
};

export default dashboard;
