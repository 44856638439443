<script setup>
import { onMounted, ref } from 'vue';
import { useListenForGaEvents, useRoute, useToggle } from '@base/hooks';
import { FormFlowListHeader, FormFlowTableList } from '@flow/components/form-flow';
import { useFormFlowListActions } from '@flow/composables/useFormFlowListActions';
import { useI18n } from '@/locales';
import { useStore } from '@/store';

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const { isEnabled: toggleFlowFolderEnabled } = useToggle('toggle_flow_folder_enabled');

const root = ref();

useListenForGaEvents(root, 'automation-form-flows-app');

const tabItems = [
  { title: t('flowModule.list.tab.flow'), name: 'flow', to: { name: 'formFlowListFlows' } },
  {
    title: t('flowModule.list.tab.folders'),
    name: 'folder',
    to: { name: 'formFlowListFolders' },
  },
];

const { handleNew } = useFormFlowListActions();

const fetchFormFlows = async () => {
  store.commit('formFlow/SET_LINKS', { self: window.location.href });
  await store.dispatch('formFlow/fetchFormFlows');
};

onMounted(() => {
  // TOGGLE_FLOW_FOLDER_ENABLED
  // TODO: Remove if/else condition and keep if content
  if (toggleFlowFolderEnabled.value) {
    if (route.name === 'formFlowListFlows') {
      fetchFormFlows();
    }
  } else {
    fetchFormFlows();
  }
});
</script>

<template>
  <section
    ref="root"
    :class="$style.wrapper"
  >
    <FormFlowListHeader
      data-testid="formFlowListHeader"
      @handle-new="handleNew"
    />
    <!--
      TOGGLE_FLOW_FOLDER_ENABLED
      TODO: remove feature flag and keep template default
    -->
    <FeatureFlag name="toggleFlowFolderEnabled">
      <template #default>
        <div>
          <XTabs
            :items="tabItems"
            :active-tab="$route.name === 'formFlowListFolders' ? 'folder' : 'flow'"
            :class="$style.tabs"
          />
          <router-view />
        </div>
      </template>
      <template #off>
        <FormFlowTableList />
      </template>
    </FeatureFlag>
  </section>
</template>

<style lang="scss" module>
.tabs {
  width: 100%;

  [role='tabpanel'] {
    display: none;
  }
}

.wrapper {
  margin: var(--space-large);
}
</style>
