import { useRoute } from '@base/hooks';
import { computed } from 'vue';

export function useFormFlowListFilters() {
  const route = useRoute();

  const hasFilters = computed(() => {
    const { name, enabled } = route.query;
    const startDate = route.query.startDate ?? route.query.start_date;
    const endDate = route.query.endDate ?? route.query.end_date;

    return !!(name || enabled || startDate || endDate);
  });

  return {
    hasFilters,
  };
}
