const signerArea = {
  tabs: {
    pending: {
      title: 'Pendientes',
    },
    signed: {
      title: 'Firmados',
    },
  },
  batchList: {
    sectionTitle: 'Mi área de firma',
    title: 'Firmas pendientes',
    subtitle: 'Revisa y firma tus documentos aquí',
    documentsToSign: 'Documentos para firmar',
    signButton: 'Firmar',
    empty: {
      title: 'No tienes firmas pendientes por ahora',
      text: 'Ahora puedes ver y firmar tus documentos pendientes aquí.',
      help: 'Disponible para firma hasta',
    },
    headers: {
      sender: 'Remitente',
      documentation: 'Datos de registro',
      documentsCount: 'Documentos',
      deadline: 'Disponible para firmas hasta',
      selectAll: 'Seleccionar todos',
      selectAllMobile: 'Seleccionar todos de la página',
    },
    documentationKind: {
      person: 'CPF: {0}',
      company: 'CNPJ: {0}',
    },
    noDocumentation: 'CPF/CNPJ no registrado',
    documentsCount: '{0} documento | {0} documentos',
    deadline: 'Disponible para firma hasta {0} | {0}',
    seeMore: 'Ver más documentos',
  },
  receiptList: {
    title: 'Documentos firmados',
    deadlineAt: 'En {date}',
    deadlineUntil: 'Hasta {date}',
    signedAt: 'Firmado en {date}',
    empty: {
      title: 'No hay documentos firmados por ahora',
      text: 'Podrás encontrar y descargar tus documentos firmados aquí',
      help: 'Conoce más sobre tu área',
    },
    documentStatuses: {
      canceled: 'Cancelado',
      closed: 'Finalizado',
      running: 'En proceso',
    },
    table: {
      header: {
        documents: 'Documentos',
        signers: 'Firmantes',
        status: 'Estatus',
        download: 'Descargar',
      },
    },
    signers: {
      showLess: 'Ver menos firmantes',
      showMore: 'Ver más firmantes',
    },
  },
  batchWarning: {
    waiting: {
      title: 'Esperando a otros firmantes',
      text: 'Otros firmantes deben firmar antes que tú. Intenta firmar nuevamente más tarde.',
    },
    allUnable: {
      title: 'No disponible para firmar',
      text: 'Todos los documentos de este lote no se pueden firmar en este momento. Revisa las posibles razones:',
      status: {
        blocked: 'El proceso de firma está temporalmente pausado',
        finished: 'El proceso de firma ha finalizado o fue cancelado',
        signed: 'Ya firmaste este documento junto con otros anteriormente',
        refused: 'Rechazaste la firma de estos documentos anteriormente',
        unlinked:
          'El remitente del documento realizó cambios en el proceso y ya no eres un firmante de este lote',
      },
    },
    partialUnable: {
      title: 'Documento no disponible | Documentos no disponibles',
      text: '{0} de {1} documentos de este lote no se pueden firmar por las siguientes razones:',
      status: {
        blocked: '{0} - Documento pausado - esperando acción del remitente',
        finished: '{0} - El proceso de firma ha finalizado o fue cancelado',
        signed: '{0} - ya fue firmado por ti en otro lote',
        refused: '{0} - ya fue rechazado por ti en otro lote',
        unlinked:
          '{0} - El remitente del documento realizó cambios en el proceso y ya no eres un firmante',
        notSignable: '{0} - No es posible firmar el documento en este momento',
      },
    },
  },
  ctaCard: {
    title: 'Comienza a enviar documentos para firmar',
    imageAlt: 'Persona usando tablet',
    advantages: {
      advantage1: 'Validez jurídica',
      advantage2: 'Firmas digitales y electrónicas',
      advantage3: 'Envíos vía WhatsApp, SMS o correo electrónico',
      advantage4: 'Diversos tipos de autenticación',
    },
    action: 'Probar gratis',
  },
  helpCard: {
    title: '¿Necesitas ayuda?',
    helpCentral: 'Preguntas frecuentes',
    support: 'Contacto con soporte',
    sales: 'Contacto con ventas',
  },
  topbar: {
    dropdown: {
      myArea: 'Mi área de firma',
      signerDashboard: 'Documentos para firmar',
      accounts: 'Cuentas',
      config: 'Configuración del perfil',
      logout: 'Salir',
    },
    action: {
      title: 'Enviar documento para firmas',
      subtitle: 'Acceder a la cuenta para realizar el envío',
    },
  },
  dataConfirmation: {
    title: {
      phoneValidation:
        'Confirma tu número de celular y conoce si tienes otros documentos pendientes',
      cpfBirthdayValidation: 'Ingresa tu CPF y descubre si tienes documentos pendientes',
    },
    titleOld: {
      phoneValidation:
        'Confirma tu número de celular y descubre si tienes otros documentos pendientes',
      cpfBirthdayValidation: 'Falta poco para finalizar tu registro',
    },
    actions: {
      confirm: 'Confirmar',
      complete: 'Completar',
    },
    placeholder: {
      cpf: 'CPF',
      birthday: 'Fecha de nacimiento',
    },
    completeSignupSuccess: '¡Registro completado con éxito!',
    completeSignupError: 'Error al completar el registro',
  },
  didntFindYourDocuments: '¿No encontraste tu documento?',
  fakeDoorActivator: '¿Buscas documentos ya firmados?',
  fakeDoorModal: {
    titleSmall: 'Descubre dónde encontrar los documentos ya firmados',
    title: 'Documentos ya firmados',
    paragraph1:
      'Por el momento, solo es posible visualizar un documento que ya firmaste ingresando a la copia enviada por correo electrónico o vía WhatsApp.',
    paragraph2: '¡Estamos trabajando para mejorar tu experiencia y pronto traeremos novedades!',
    action: 'Entendido',
  },
  clearPhoneNumberModal: {
    title: '¿Estás seguro de esto?',
    text: 'Al no guardar tu número de celular en la plataforma, es posible que en el futuro aparezcan documentos vinculados a ella y estos documentos no se presentarán aquí.',
  },
  phoneNumberValidation: {
    title: {
      pending: 'Confirma tu número de celular y recibe documentos vinculados a este número',
      empty: 'Agrega tu número de celular y recibe documentos vinculados a este número',
      expiring: '¿Sigue siendo tu número de celular?',
    },
  },
  cellPhoneConfirmation: {
    title: 'Confirma tu número de celular y conoce si tienes otros documentos pendientes',
    button: 'Confirmar',
    phoneNumberValidation: {
      success: 'Cambio guardado con éxito',
      failed: 'Número de celular inválido',
      confirm: 'Confirmación exitosa',
    },
    loadingSave: 'Buscando documentos enviados al celular registrado...',
    modal: {
      header: 'Validación del celular',
      title: 'Token de validación',
      info: 'Verifica el token enviado vía SMS al celular {0}{1} e introdúcelo a continuación.',
      changeNumberLink: 'Cambiar número de celular',
      insertToken: 'Introduce el Token para finalizar',
      resendSMS: 'Reenviar Token vía SMS',
      timeToResend: 'Espera {timer} para reenviar',
      noDocuments: 'No encontramos documentos enviados al celular registrado',
      success: 'Encontramos documentos enviados al celular registrado',
      actions: {
        close: 'Cerrar',
        confirm: 'Confirmar',
      },
      tokenValidation: {
        failed: 'Token inválido',
        success: 'Token reenviado con éxito',
      },
    },
  },
  notificationConfigs: {
    dismissEmailOptions: 'Desmarca el/los correo(s) electrónico(s) que no desea recibir',
    signatureReceipt: 'Recordatorios sobre la fecha límite de firma',
    signatureDeadlineReminder: 'Comprobante de firma',
    signedDocumentFinished: 'Documento firmado y finalizado',
    updatedWithSuccess: 'Cambios guardados con éxito',
    goToSignatureArea: 'Ir al área de firmas',
  },
  previewEnvelope: {
    signerArea: 'Área de firmas',
    envelope: 'Sobre',
    selectADocumentToPreview: 'Seleccione un documento para previsualizarlo.',
    errorWhenPreview:
      'Ocurrió un error al cargar tu documento. Intenta descargar el documento o verlo en otro navegador.',
    signedDocumentInProccessing:
      'El documento firmado está siendo procesado, en unos momentos la página se actualizará.',
  },
  documentDeadline: 'Plazo',
  documentDeadlineDateFormat: 'DD/MM/YYYY [a las] HH:mm',
  documentFinishedAt: 'Finalizado en',
};

export default signerArea;
