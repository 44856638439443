import { useStore } from '@/store';
import { computed } from 'vue';

export const useAccountInfo = () => {
  const store = useStore();
  const account = computed(() => store?.getters['info/getAccount'] || {});

  const trialExpiresAt = computed(() => new Date(account.value.trialExpiresAt));

  const isTrial = computed(() => account.value.trial);
  const isTrialEnding = computed(() => account.value.trialEnding);
  const isActiveTrial = computed(() => isTrial.value && trialExpiresAt.value >= new Date());

  const isSubscribed = computed(() => account.value.subscribed);

  const isAccountPlan = (plan) => account.value.plan === plan;

  return {
    isTrial,
    isTrialEnding,
    isActiveTrial,
    isSubscribed,
    account,
    isAccountPlan,
  };
};
