<script setup>
import { useStore } from '@/store';
import { computed, ref } from 'vue';

defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close', 'rename']);

const store = useStore();

const selectedFolder = computed(() => store.getters['flowFolder/getSelectedFolder']);

const name = ref('');
const isRenameButtonEnabled = computed(
  () => name.value.length > 0 && name.value !== selectedFolder.value?.name
);

const closeModal = () => {
  emit('close');
  name.value = '';
};

const handleOpenModal = () => {
  name.value = selectedFolder.value?.name || '';
};
</script>

<template>
  <XModal
    :is-open="showModal"
    :title="$t('flowModule.folders.rename.modal.title')"
    data-testid="formFlowRenameFolderModal"
    @opened="handleOpenModal"
    @close="closeModal"
  >
    <template #default>
      <div :class="$style.body">
        <XTextInput
          v-model="name"
          :placeholder="$t('flowModule.folders.rename.modal.placeholder')"
          full-width
          data-testid="renameInput"
        />
      </div>
    </template>
    <template #actions>
      <div :class="$style.actions">
        <XButton
          design="outlined"
          data-testid="cancelButton"
          @click="closeModal"
        >
          {{ $t('general.action.cancel') }}
        </XButton>
        <XButton
          :disabled="!isRenameButtonEnabled"
          data-testid="renameButton"
          @click="$emit('rename', { name })"
        >
          {{ $t('general.action.rename') }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.body {
  padding-top: var(--space-medium);
}

.actions {
  display: flex;
  gap: var(--space-small-xx);
}
</style>
