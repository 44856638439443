import { kebabCase, uniqueId } from 'lodash';

export const mountFieldPayload = (fields) => {
  return fields.map((payload) => {
    const name = payload.name || uniqueId(`${kebabCase(payload.label)}-`);
    const { kind } = payload;
    let { header, paragraph } = payload;

    if (kind === 'paragraph') header = null;
    else if (kind === 'header') paragraph = null;

    return {
      ...payload,
      name,
      type: payload.kind,
      header,
      paragraph,
    };
  });
};
