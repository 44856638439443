<template>
  <aside
    v-show="open && links.navigation"
    :class="$style.sidebar"
  >
    <XSkeletonLoader
      v-if="_.isEmpty(account)"
      width="260px"
      height="64px"
      shape="rounded"
    />
    <template v-else>
      <SidebarBrandImage
        v-if="!workspaceEnabled"
        :logo-url="_.get(account, 'logoUrl')"
        :color="_.get(account, 'brand.color1')"
        :link="links.dashboard"
      />
      <SidebarOrganizationAccountsMenu
        v-else-if="workspaceEnabled && organization"
        :account="account"
        :dashboard-url="links.dashboard"
        :organization="organization"
        :workspaces-url="workspacesUrl"
      />
    </template>
    <div
      ref="navigation"
      :class="$style.navigation"
      @scroll="handleScroll"
    >
      <!-- TODO: Move to 'SidebarActionButton' -->
      <div
        v-if="canCreateDocument"
        :class="$style.action"
      >
        <XButton
          :data-testid="documentUploadModalButtonDataTestId"
          @click="handleNewDocuments"
        >
          <XIcon
            icon="plus"
            left
          />
          {{ $t('layout.sidebar.addDocuments') }}
        </XButton>
      </div>
      <SidebarNavigationMenu
        :bounces="bounces"
        :downloads="downloads"
        :links="links.navigation"
      />
    </div>
    <SidebarCloseButton
      :open="open"
      @close="$emit('close')"
    />
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import useGA from '@base/hooks/useGA';
import { useValidateAccountActions } from '@base/hooks/useValidateAccountActions';
import { useAccountFeature } from '@base/hooks/useAccountFeature';
// TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
// TODO: Decide if useTrialExperiment is removed
import { useTrialExperiment } from '@base/composables';

import SidebarBrandImage from '@base/components/layout-default/sidebar/SidebarBrandImage.vue';
import SidebarNavigationMenu from '@base/components/layout-default/sidebar/SidebarNavigationMenu.vue';
import SidebarCloseButton from '@base/components/layout-default/sidebar/SidebarCloseButton.vue';
import SidebarOrganizationAccountsMenu from '@base/components/layout-default/sidebar/SidebarOrganizationAccountsMenu/SidebarOrganizationAccountsMenu.vue';

export default {
  name: 'LayoutDefaultSidebarContainer',
  components: {
    SidebarBrandImage,
    SidebarCloseButton,
    SidebarNavigationMenu,
    SidebarOrganizationAccountsMenu,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isEnabled: isEnvelopeSetupPreferenceEnabled } = useAccountFeature(
      'envelope-setup-preference-enabled'
    );

    // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
    // TODO: Decide if isTrialExperiment is removed
    const { isTrialExperiment } = useTrialExperiment();

    return {
      isEnvelopeSetupPreferenceEnabled,
      // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
      // TODO: Decide if isTrialExperiment is removed
      isTrialExperiment,
    };
  },
  data: () => ({
    bounces: 0,
    downloads: {
      notify: 0,
      processing: false,
    },
    downloadInterval: null,
    emailInterval: null,
    timeout: null,
  }),
  computed: {
    ...mapGetters({
      account: 'info/getAccount',
      links: 'info/getLinks',
      environment: 'environment/getVariables',
      navigationLinks: 'info/getSidebarLinks',
      downloadProcessingData: 'info/getDownloadProcessing',
      isToggleEnabled: 'featureFlags/isToggleEnabled',
      users: 'info/getUsers',
      organization: 'workspaces/getOrganization',
    }),
    // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
    // TODO: Decide if documentUploadModalButtonDataTestId is removed or refactored
    documentUploadModalButtonDataTestId() {
      return this.isTrialExperiment
        ? 'documentUploadModalButtonTrialExperiment'
        : 'documentUploadModalButton';
    },
    isDownloadLink() {
      return window.location.pathname === this.links.downloadsPacks;
    },
    canClearInterval() {
      return (
        !this.downloadProcessingData?.processing &&
        (this.downloadProcessingData?.notify === 0 || !this.isDownloadLink)
      );
    },
    // TOGGLE_ENVELOPE_SETUP
    // TODO: Keep only if inner code on canCreateDocument computed
    canCreateDocument() {
      if (this.isToggleEnabled('toggleEnvelopeSetupScope', { scope: 1 })) {
        return this.links.newEnvelope;
      }
      return this.links.newDraftPack;
    },
    // TOGGLE_ENVELOPE_SETUP
    // TODO: Keep only if inner code on createDocumentLink computed
    createDocumentLink() {
      if (
        this.isToggleEnabled('toggleEnvelopeSetupScope', { scope: 1 }) &&
        this.isEnvelopeSetupPreferenceEnabled
      ) {
        return this.links.newEnvelopeCurrentFolder || this.links.newEnvelope;
      }
      return this.links.newDraftPackCurrentFolder || this.links.newDraftPack;
    },
    workspaceEnabled() {
      return this.users.user?.workspaceEnabled || this.users.workspaceEnabled;
    },
    currentActiveWorkspaceInfo() {
      const currentWorkspace = this.organization?.workspaces.find((workspace) =>
        workspace.accounts.some((account) => account.id === this.account.id)
      );

      return currentWorkspace;
    },
    workspacesUrl() {
      return this.users?.user?.links?.workspaces;
    },
  },
  watch: {
    users: {
      handler(newUsersValue) {
        if (this.workspaceEnabled) {
          const links = newUsersValue?.user?.links || newUsersValue?.links;
          const { organization } = links;
          this.fetchOrganization({ url: organization });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.initPolling();
  },
  beforeDestroy() {
    clearInterval(this.downloadInterval);
    clearInterval(this.emailInterval);
  },
  methods: {
    ...mapActions('info', ['getEmailsBounces', 'getIsDownloadProcessing']),
    ...mapActions('downloads', ['fetchDownloads']),
    ...mapActions('workspaces', ['fetchOrganization']),
    useGA,
    initPolling() {
      if (_.get(this.navigationLinks, 'documents.downloads')) this.isDownloadProcessing();
      if (_.get(this.navigationLinks, 'management.trackingNotifications')) this.fetchEmailBounces();
    },
    async fetchEmailBounces() {
      this.emailInterval = setInterval(async () => {
        try {
          const { quantity } = await this.getEmailsBounces();
          this.bounces = quantity;
        } catch (error) {
          throw error;
        }
      }, this.environment.emailBouncesPollingInterval);
    },
    async isDownloadProcessing() {
      let countNotify = 0;
      this.downloadInterval = setInterval(async () => {
        await this.getIsDownloadProcessing();
        if (this.canReloadDownloadPage(countNotify)) this.fetchDownloads();
        countNotify = _.get(this.downloadProcessingData, 'notify');
        if (this.canClearInterval) clearInterval(this.downloadInterval);
        this.downloads = { ...this.downloads, ...this.downloadProcessingData };
      }, this.environment.downloadPacksPollingInterval);
    },
    canReloadDownloadPage(countNotify) {
      return _.get(this.downloadProcessingData, 'notify') > countNotify && this.isDownloadLink;
    },
    dispatchGA() {
      this.useGA('event', 'buttonSendDocumentClick', 'menu-app');
    },
    handleNewDocuments() {
      this.dispatchGA();
      const { validateAccountActions } = useValidateAccountActions();

      validateAccountActions(
        () => {
          this.$goTo(this.createDocumentLink);
        },
        { action: 'createDocument' }
      );
    },
    setCachedScroll() {
      if (localStorage.getItem('scrollTopNavigation')) {
        this.$refs.navigation.scrollTop = localStorage.getItem('scrollTopNavigation');
      }
    },
    handleScroll: _.debounce(function searchDebounce() {
      localStorage.setItem('scrollTopNavigation', this.$refs.navigation.scrollTop);
    }, 400),
  },
};
</script>

<style lang="scss" module>
.sidebar {
  position: fixed;

  /* ISSUE: https://github.com/clicksign/excalibur/issues/790
    TODO: Replace --zindex-level-400 with a proper z-index variable for the sidebar */
  z-index: var(--zindex-level-400);
  top: 0;
  left: 0;
  width: 272px;
  height: 100vh;
  background-color: var(--color-brand-support-03-200);
}

.navigation {
  overflow-y: auto;
  height: calc(100vh - 65px);
  scrollbar-color: var(--color-neutral-500);
  scrollbar-width: thin;

  .action {
    display: flex;
    justify-content: center;
    padding: var(--space-regular) 0;
  }

  /* Works on Chrome, Edge, and Safari */

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-neutral-800);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-neutral-500);
    border-radius: var(--border-radius-medium);
  }
}
</style>
