export default {
  links: {},
  folder: {},
  folders: [],
  pagination: {},
  totalItems: 0,
  unassignedFlows: [],
  unassignedFlowsPagination: {},
  selectedFolder: {},
  selectedFlows: [],
};
