export const sanitizeDateFilters = (dates) => {
  return dates.reduce((acc, item) => {
    const { dateKind, startDate, endDate } = item;

    const key = dateKind.replace(/_([a-z])/g, (_, char) => char.toUpperCase());

    acc[key] = { start: startDate, end: endDate };
    return acc;
  }, {});
};
