<template>
  <section>
    <Transition
      name="slide-fade"
      :enter-active-class="$style.slideFadeEnterActive"
      :leave-active-class="$style.slideFadeLeaveActive"
      :enter-class="$style.slideFadeEnterFrom"
      :leave-to-class="$style.slideFadeLeaveTo"
    >
      <XCard
        v-show="isChatOpen"
        elevation="high"
        :class="$style.container"
        data-testid="hubspotHelpFormContainer"
      >
        <XSpinner
          v-if="isLoading"
          show-text
          vertical
        />
        <Transition
          name="slide-fade"
          :enter-active-class="$style.slideFadeEnterActive"
          :enter-class="$style.slideFadeEnterFrom"
        >
          <div
            v-show="!isLoading"
            :class="$style.content"
          >
            <XButton
              model="icon"
              design="link"
              size="xs"
              data-testid="closeButton"
              :class="$style.closeButton"
              @click="closeHubspotHelpForm"
            >
              <XIcon icon="close" />
            </XButton>
            <h4>{{ $t('layout.header.helpForm.title') }}</h4>
            <div :id="HUBSPOT_CONTAINER_ID"></div>
          </div>
        </Transition>
      </XCard>
    </Transition>
  </section>
</template>

<script setup>
import { useHubspotHelpForm } from '@operators/composables/useHubspotHelpForm';

const { isChatOpen, closeHubspotHelpForm, HUBSPOT_CONTAINER_ID, isLoading } = useHubspotHelpForm();
</script>

<style lang="scss" module>
.container {
  position: fixed;
  z-index: var(--zindex-level-300);
  right: var(--space-regular);
  bottom: var(--space-regular);
  width: 100%;
  max-width: 368px;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .closeButton {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  @include breakpoint('extra-small') {
    width: calc(100% - var(--space-large));
  }
}

.slideFadeEnterActive,
.slideFadeLeaveActive {
  transition: all var(--time-transition-base) cubic-bezier(1, 0.5, 0.8, 1);
}

.slideFadeEnterFrom,
.slideFadeLeaveTo {
  opacity: 0;
  transform: translateY(var(--space-regular));
}
</style>
