const pendingAnswers = {
  actionReminder: '¡Recordatorio reenviado con éxito! | ¡Recordatorios reenviados con éxito!',
  actionDisapprove: '¡Respuesta eliminada con éxito! | ¡Respuestas eliminadas con éxito!',
  // TOGGLE_BULK_ACTIONS_ON_PENDING_ANSWERS_ENABLED
  // TODO: Remove actionReminderOld and actionDisapproveOld
  actionReminderOld: '¡Recordatorio reenviado con éxito!',
  actionDisapproveOld: '¡Respuesta eliminada con éxito!',
  actionError: '¡Ocurrió un error al realizar la acción!',
  emptyText:
    'No encontramos ninguna respuesta pendiente del 2º formulario. {0} ¿te gustaría seleccionar un flujo diferente?',
  header: {
    title: 'Respuestas pendientes del 2º formulario',
    alert:
      'Revisa quién falta por completar y reenvía el recordatorio del formulario, si lo deseas',
    filter: {
      label: 'Flujos',
      placeholder: 'Selecciona el flujo',
      clear: 'Limpiar',
      button: 'Filtrar',
    },
  },
  filters: {
    apply: 'Aplicar',
    clear: 'Limpiar filtros',
    dateRangePlaceholder: 'Todo',
    flows: 'Flujos',
    search: 'Busque por correo electrónico, teléfono o nombre del flujo',
  },
  table: {
    secondRecipient: 'Destinatario',
    name: 'Nombre del flujo',
    createdAt: 'Completado el',
    action: 'Acciones',
    reminder: 'Reenviar recordatorio',
    delete: 'Eliminar respuesta',
  },
  detailsModal: {
    title: 'Detalles de pendência',
    flowDetails: 'Detalles del flujo',
    flowAnswers: 'Respuestas del 1º formulario',
    firstFlowName: 'Nombre del 1º formulario',
    secondFlowName: 'Nombre del 2º formulario',
    identifier: 'Identificador',
  },
  disapproveModal: {
    title: '¿Eliminar respuesta pendiente del flujo?',
    description: 'El enlace del segundo formulario no estará disponible para esta persona.',
  },
  whatsappReminderModal: {
    title: 'Reenviar enlace vía WhatsApp',
    description: 'El firmante recibirá nuevamente el enlace del segundo formulario.',
    alert: 'Para envíos vía WhatsApp, hay un costo de R$ 0,40 por cada recordatorio.',
  },
  bulkActions: {
    totalSelected: '{count} elemento seleccionado | {count} elementos seleccionados',
    reminder: 'Reenviar recordatorio',
    delete: 'Eliminar',
    disapproveModal: {
      title: '¿Eliminar respuestas pendientes?',
      description:
        'Al eliminar las respuestas pendientes seleccionadas, los enlaces del segundo formulario quedarán inaccesibles para los destinatarios.',
    },
    reminderModal: {
      title: '¿Reenviar recordatorios?',
      description:
        '¿Desea reenviar el recordatorio de respuesta pendiente a los destinatarios seleccionados?',
    },
    whatsappReminderModal: {
      title: '¿Reenviar recordatorios por WhatsApp?',
      description:
        'Los destinatarios seleccionados recibirán nuevamente el enlace del segundo formulario.',
      alert: 'Para envíos vía WhatsApp, hay un costo de R$ 0,40 por cada recordatorio.',
    },
  },
};

export default pendingAnswers;
