export default {
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_FOLDER: (state, payload) => {
    state.folder = payload;
  },
  SET_FOLDERS: (state, payload) => {
    state.folders = payload;
  },
  SET_PAGINATION: (state, payload) => {
    state.pagination = payload;
  },
  SET_TOTAL_ITEMS: (state, payload) => {
    state.totalItems = payload;
  },
  SET_UNASSIGNED_FLOWS: (state, payload) => {
    state.unassignedFlows = payload;
  },
  SET_UNASSIGNED_FLOWS_PAGINATION: (state, payload) => {
    state.unassignedFlowsPagination = payload;
  },
  SET_SELECTED_FOLDER: (state, payload) => {
    state.selectedFolder = payload;
  },
  SET_SELECTED_FLOWS: (state, payload) => {
    state.selectedFlows = payload;
  },
};
