<script setup>
import { useStore } from '@/store';
import { computed } from 'vue';

defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const selectedProcessesList = computed(
  () => store.getters['pendingAnswers/getSelectedProcessesList']
);
const includeNotificationByWhatsapp = computed(() =>
  selectedProcessesList.value.some((item) => item.notificationByWhatsapp)
);
const includeNotificationByEmail = computed(() =>
  selectedProcessesList.value.some((item) => !item.notificationByWhatsapp)
);
</script>

<template>
  <XModal
    :is-open="showModal"
    data-testid="pendingAnswersBulkActionReminderModal"
    @close="$emit('close')"
  >
    <template #header>
      {{
        includeNotificationByEmail
          ? $t('pendingAnswers.bulkActions.reminderModal.title')
          : $t('pendingAnswers.bulkActions.whatsappReminderModal.title')
      }}
    </template>
    <p>
      {{
        includeNotificationByEmail
          ? $t('pendingAnswers.bulkActions.reminderModal.description')
          : $t('pendingAnswers.bulkActions.whatsappReminderModal.description')
      }}
    </p>
    <XAlert
      v-if="includeNotificationByWhatsapp"
      full-width
      type="warning"
      data-testid="pendingAnswersBulkActionReminderModalAlert"
    >
      {{ $t('pendingAnswers.bulkActions.whatsappReminderModal.alert') }}
    </XAlert>
    <template #actions>
      <XButton
        design="outlined"
        data-testid="pendingAnswersBulkActionReminderModalCancelButton"
        @click="$emit('close')"
      >
        {{ $t('general.action.close') }}
      </XButton>
      <XButton
        data-testid="pendingAnswersBulkActionReminderModalConfirmButton"
        @click="$emit('confirm')"
      >
        {{ $t('general.action.resend') }}
      </XButton>
    </template>
  </XModal>
</template>
