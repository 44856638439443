const account = {
  emailVerificationModal: {
    title: 'Para continuar, confirma tu correo electrónico',
    description: 'Haz clicK en el enlace que enviamos a {email}',
    notReceived: 'No recibí el correo',
    success: 'Correo reenviado',
    failed: 'Error al reenviar el correo. Inténtalo de nuevo.',
  },
  message_template: {
    message_template_saved: 'Mensajes guardados',
    create_new_message_template: 'Crear nuevo mensaje',
    info: 'Crea un mensaje para reutilizarlo, se guardará para usar al enviar documentos.',
    name: 'Nombre',
    enter_signer_name: 'Ingresa el nombre del firmante',
    message: 'Mensaje',
    cancel: 'Cancelar',
    create: 'Crear',
    remove: 'Eliminar',
    delete_message_template: 'Eliminar mensaje guardado',
    delete_message_info: '¿Estás seguro de que deseas eliminar el mensaje a continuación?',
  },
  blocked: {
    loginLink: 'Iniciar sesión con otra cuenta',
    generic: {
      info: {
        title: 'Error al acceder a {0}',
        description:
          'Esta cuenta puede tener facturas pendientes de pago o puede haber sido cancelada por solicitud del administrador.',
        unblock:
          'Inicia sesión en otra cuenta o contacta con el equipo de soporte para recuperar el acceso.',
      },
      help: {
        description: '¿Dudas? Visita nuestra {0}.',
      },
    },
    overduePayment: {
      common: {
        unpaidTitle: 'La cuenta {accountName} ha sido bloqueada',
        title: 'Para recuperar ahora el acceso a la cuenta, regulariza los pagos',
        subtitle:
          'No se pudo confirmar el pago de la factura y, por ese motivo, la cuenta fue temporalmente bloqueada.',
        alert:
          '{email} y los demás responsables recibieron un correo con instrucciones para el desbloqueo',
      },
      admin: {
        unpaidTitle: 'Pago no identificado',
        unpaidSubtitle:
          'No se pudo confirmar el pago realizado en {0}. Para recuperar el acceso a la cuenta {1}, regulariza la deuda a continuación.',
        action: 'regulariza la deuda a continuación',
      },
      paymentVoucher: {
        admin: {
          description: '{0}. Si la deuda ya ha sido regularizada {1}.',
          attemptInfo: 'Tu acceso será liberado mientras analizamos el intento de pago',
          action: 'haz click aquí',
        },
        unpaid: {
          description:
            'Si la deuda ya ha sido regularizada, {0} y recupera el acceso mientras analizamos el pago.',
          action: 'envía el comprobante',
        },
      },
      creditCardAlert: {
        text: 'No te preocupes más por retrasos y bloqueos. En la factura, ¡elige pagar con tarjeta de crédito!',
        button: 'Pagar con tarjeta',
      },
      paymentAttemptModal: {
        header: 'Estamos analizando el intento de pago',
        text: 'Nuestra verificación puede tardar hasta 72 horas. El acceso a la cuenta estará indisponible hasta que el pago sea identificado.',
        button: 'Entendido',
      },
      needHelp: '¿Dudas? Visita nuestra {0}.',
      error: 'Hubo un error al enviar los datos, intenta nuevamente',
      fetchInvoiceError: 'Hubo un error al buscar las facturas, intenta nuevamente',
    },
    forcedChurn: {
      title: 'La cuenta {accountName} ha sido desactivada',
      subtitle:
        'La cuenta fue desactivada porque no identificamos el pago de facturas ya vencidas. Al hacer clic en “Reactivar ahora”, {0} y el acceso a la cuenta será {1} mientras esperamos la confirmación del pago. {2}.',
      subtitleInvoiceWarning: 'Se emitirá una nueva factura con el monto total adeudado',
      subtitleUnblockWarning: 'Liberado de inmediato',
      // TOGGLE_NEW_SELF_REACTIVATION_APPROACH_ENABLED
      // TODO: remove subtitleOld below
      subtitleOld:
        'La cuenta ha sido desactivada porque aún no hemos identificado el pago de las deudas a continuación:',
      reactivation: {
        description:
          'Haciendo click en “Reactivar ahora”, se emitirán las facturas y el acceso a la cuenta será {0} mientras esperamos la confirmación del pago. {1}.',
        bold: 'liberado inmediatamente',
        help: 'Entender la reactivación',
      },
      actions: {
        return: 'Regresar al panel de cuentas',
        reactivate: 'Reactivar ahora',
        sendPaymentVoucher: 'Enviar comprobante',
      },
      common: {
        subtitle:
          'No se pudo confirmar el pago de la factura y, por ese motivo, la cuenta ha sido desactivada.',
        paymentVoucherDescription:
          'Si la deuda ya ha sido regularizada, {0} para agilizar el desbloqueo.',
        paymentVoucherLink: 'envía el comprobante',
      },
    },
    cancellation: {
      title: 'Cuenta cancelada el {date}',
      info: 'Si cambias de opinión, tienes hasta el {date} para reactivarla. Al final de este plazo, la cuenta será eliminada definitivamente.',
      helpText: '¿Necesitas ayuda? Visita nuestro Centro de Ayuda o',
      support: 'contacta con soporte',
      reactivateButton: 'Quiero reactivar la cuenta',
      reactivateError: 'Algo salió mal. Actualiza la página e inténtalo nuevamente.',
      notAdmin: {
        text: 'Para recuperarla, el administrador de la cuenta debe solicitar la reactivación hasta el {date}.',
        description: 'Después de esta fecha, la cuenta será eliminada definitivamente.',
      },
      modal: {
        title: '¿Deseas reactivar tu cuenta?',
        text: 'Al confirmar, la cuenta será reactivada inmediatamente.',
        invoiceWarning:
          'El plan contratado anteriormente será retomado y se emitirá una factura referente a la mensualidad.',
        resumedPlan: 'El plan contratado anteriormente será retomado.',
        confirmButton: 'Confirmar reactivación de la cuenta',
      },
    },
    ipRange: {
      title: 'Tu IP no está autorizada para acceder a la cuenta {0}',
      subtitle: 'Contacta con el administrador de esta cuenta para obtener acceso',
      actions: {
        returnToAccounts: 'Regresar a la selección de cuentas',
      },
      help: '¿Necesitas ayuda? Visita nuestra {0} o {1}.',
    },
  },
  denied: {
    title: 'Tu cuenta ha sido temporalmente suspendida',
    description:
      'Detectamos actividades en esta cuenta que violan nuestros {0}. Por eso, la suspendimos y pausamos los documentos en proceso de firma, como medida de precaución.',
    complement:
      'Contacta con nuestro equipo de soporte si deseas reactivarla. Por favor, contáctanos pronto para evitar que la cuenta sea bloqueada de forma definitiva.',
    action: 'Hablar con el equipo de soporte',
    logout: 'Cerrar sesión',
  },
  chooseAccount: {
    title: '¿Qué deseas acceder?',
    accountType: 'Cuenta',
  },
  emailValidation: {
    step: 'Paso 2 de 2',
    title: 'Ahora solo confirma tu correo electrónico',
    description:
      'Por seguridad, hemos enviado una solicitud de confirmación a tu correo. Revisa tu bandeja de entrada y confirma para ver y firmar tus documentos.',
    inputLabel: 'Correo electrónico actual',
    inputPlaceholder: 'correo.introducido@empresa.com',
    help: 'Aprende más sobre tu área',
    success: 'Correo reenviado',
    failed: 'Error al reenviar el correo. Inténtalo nuevamente.',
    actions: {
      resend: 'Reenviar',
    },
    timer: {
      message: 'Podrás reenviar el correo en {0}',
    },
  },
  getStarted: {
    title: 'Todo listo para enviar tu primer documento. ¿Empezamos?',
    goToNewEnvelope: 'Sí, vamos',
    skip: 'Omitir este paso',
  },
};

export default account;
