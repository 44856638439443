<template>
  <header :class="$style.header">
    <slot name="top" />

    <div :class="$style.container">
      <SignerLogo v-show="showLogo" />

      <slot name="left" />

      <span :class="$style.spacer" />

      <slot name="right" />

      <TheHeaderUserActions
        v-if="showUserActions"
        :user="user"
        :options="options"
        :profile="profile"
        :hide-account-option="hideAccountOption"
        @logout="$emit('logout')"
      />
    </div>

    <slot name="down" />
  </header>
</template>
<script>
import { defineComponent } from 'vue';
import SignerLogo from '@signer-area/components/SignerLogo.vue';
import TheHeaderUserActions from './TheHeaderUserActions/TheHeaderUserActions.vue';

export default defineComponent({
  name: 'TheHeader',
  components: {
    TheHeaderUserActions,
    SignerLogo,
  },
  props: {
    hideAccountOption: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
    showUserActions: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: String,
      default: 'operator',
    },
  },
  emits: ['logout'],
});
</script>

<style lang="scss" module>
.header {
  position: relative;
  z-index: var(--zindex-level-300);
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-0);
  box-shadow: var(--shadow-small);
  transition: all ease var(--animate-duration);
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  min-height: 64px;
  padding: var(--space-small-xx) var(--space-regular);
  gap: var(--space-regular);
}

.spacer {
  margin-left: auto;
}
</style>
